#pages[data-route="grocery"] {background: $nude; padding: 60px 120px 0 360px;
    & .block-first {margin-left: -120px;
        & .banner-grocery {z-index: 1; width: 100%; height: 600px; background-size: cover; background-position-x: 77%;}
        & .right-side {padding-top: 60px;
            & .address-schedules {color: $black; line-height: 24px; -webkit-text-stroke: 1px; padding-top: 30px;
                & svg {width: 15px; margin-top: 5px;
                    & path {fill: $gold;}
                }
                & .address, .schedules {margin-bottom: 30px;}
                & .detail {
                    & a {color: $gold;
                        &:hover {color: $red-portugal;}
                    }
                    & .line-day {grid-template-columns: 80px 1fr;
                        & .left {
                            & .day {width: 70px; color: $gold; margin-right: 5px;}
                        }
                        & .right {
                            & .closed {color: #b82827;}
                            & .schedule {
                                & .schedule-single {display: inline-block; width: 50px;}
                                & .schedule-separator {margin: 0 2px;}
                            }
                        }
                    }
                }
            }
            & h1 {z-index: 2; width: 200%; margin-left: -200px;
                & svg {width: 70px; margin: 10px 20px 0 0;
                    & path {fill: $gold;}
                }
                & .two-lines {
                    & span {font-family: $charm; color: $gold; line-height: 85px; -webkit-text-stroke: 3px; text-transform: initial;}
                }
            }
        }
    }
    & .block-second {margin-top: -80px;
        & .slogan {z-index: 1; padding-bottom: 90px;
            & .container {width: 215px; color: $black; line-height: 36px; border-top: 5px solid $black; border-bottom: 5px solid $black; padding: 10px 20px;}
        }
        & img {z-index: 2; width: 110%; border: 10px solid $white; box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 6px 6px 6px rgba(0, 0, 0, .23);}
    }
    & .block-reassurance {height: 530px; margin: 50px 0 100px;
        & .left {
            & .background-gold {top: -400px; left: 70px; background: $gold; width: 250px; height: 600px;}
            & .left-picture {z-index: 1; bottom: 50px; width: 45%; height: auto; border: 10px solid $white; box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 6px 6px 6px rgba(0, 0, 0, .23);}
            & .right-picture {bottom: 0; right: 0; width: 65%; height: auto; box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 6px 6px 6px rgba(0, 0, 0, .23);}
        }
        & .right {padding: 120px 0 40px 40px;
            & h2 {color: $gold; margin-bottom: 20px;}
            & .block-dash {top: 135px; left: -130px; background-color: $gold; width: 150px; height: 5px; z-index: 1;}
            & .summary {
                & p {color: #808080; line-height: 27px; letter-spacing: 1px;
                    &:first-child {margin-bottom: 10px;}
                }
            }
        }
    }
    & .block-department {
        & h2 {width: 300px;
            & svg {width: 40px; margin-right: 20px;
                & path {fill: $gold;}
            }
            & span {font-family: $charm; color: $gold; line-height: 50px; -webkit-text-stroke: 3px;}
        }
        & .departments {padding: 2vw 0;
            & .single-department {
                & img {width: 100%; height: auto; border-radius: 55px; box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);}
                & h3 {font-family: $oswald; letter-spacing: .5px; margin: 0; padding: 5px 20px;}
            }
        }
    }
    & .block-menu {margin-top: 50px; padding: 40px 120px 0 0;
        & h2 {width: 480px;
            & svg {width: 40px; margin-right: 20px;
                & path {fill: $gold;}
            }
            & span {font-family: $charm; color: $gold; line-height: 50px; -webkit-text-stroke: 3px;}
        }
        & .information {z-index: 2; position: sticky; top: 60px; background: $black; width: calc(100vw - 240px); height: 115px; margin: 30px -120px 0; padding: 20px 270px 20px 170px;
            & p {color: $white;
                &.clignotement {color: $red-portugal; animation-duration: 2s; animation-name: clignotement; animation-iteration-count: infinite; transition: none; margin: 0;}
            }
            @keyframes clignotement {
                0%   {opacity:1;}
                59%  {opacity:0;}
                60%  {opacity:1;}
                100% {opacity:1;}
            }
            & a {background: $red-portugal; border: 2px solid $red-portugal; border-radius: 15px; color: $white; padding: 10px 20px; transition: $transition-hover;
                & svg {width: 25px; height: 25px; margin-right: 10px;
                    & path {fill: $white; transition: $transition-hover;}
                }
                &:hover {background: $white; color: $red-portugal; transition: $transition-hover;
                    & svg {
                        & path {fill: $red-portugal; transition: $transition-hover;}
                    }
                }
            }
        }
        & .separator-block {height: 50px; margin-bottom: -50px; background: $nude; z-index: 1; position: relative;}
        & .menus {background: $white; padding: 50px;
            & .menu-detail {
                & .dish-single {margin-bottom: 30px;
                    & .top-line {width: calc(100% - 30px); grid-template-columns: 15px 1fr 80px 70px;
                        & .help-block {padding-top: 5px;
                            & svg {width: 15px; height: 15px;
                                & path {fill: $gold;}
                                &:hover {cursor: pointer;}
                            }
                            & .modal-preview {z-index: 9; top: 60px; left: 240px; width: calc(100vw - 240px); height: calc(100vh - 60px); background: $nude; padding: 0;
                                & .information {z-index: 1; background: $black; width: calc(100vw - 240px); height: 115px; margin: 0 -120px 0 0; padding: 20px 270px 20px 170px;
                                    & p {color: $white;}
                                    & .clignotement {color: $red-portugal; animation-duration: 2s; animation-name: clignotement; animation-iteration-count: infinite; transition: none; margin: 0;}
                                    @keyframes clignotement {
                                        0%   {opacity:1;}
                                        59%  {opacity:0;}
                                        60%  {opacity:1;}
                                        100% {opacity:1;}
                                    }
                                    & a {background: $red-portugal; border: 2px solid $red-portugal; border-radius: 15px; color: $white; padding: 10px 20px; transition: $transition-hover;
                                        & svg {width: 25px; height: 25px; margin-right: 10px;
                                            & path {fill: $white; transition: $transition-hover;}
                                        }
                                        &:hover {background: $white; color: $red-portugal; transition: $transition-hover;
                                            & svg {
                                                & path {fill: $red-portugal; transition: $transition-hover;}
                                            }
                                        }
                                    }
                                }
                                & .content-block {
                                    & .left-column {padding: 40px 0 40px 40px;
                                        & .close-button {width: max-content; margin-bottom: 60px;
                                            & svg {width: 20px; height: 20px;
                                                & path {fill: $gold;}
                                            }
                                            & span {color: $gold; padding-left: 5px;
                                            }
                                            &:hover {
                                                & span {text-decoration: underline;}
                                            }
                                        }
                                        & .name {line-height: 42px; margin-bottom: 30px;}
                                        & .description {line-height: 27px; width: 90%;}
                                        & .modal-detail {margin-top: 20px;
                                            & .single-capacity {background: #ddd; border-radius: 8px; height: 36px; padding: 5px 10px;}
                                        }
                                    }
                                    & img {border-radius: 55px; box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2);
                                        width: calc(100% - 60px);
                                        justify-self: center;}
                                }
                            }
                        }
                        & .dish-description {
                            & .name {font-family: $oswald; letter-spacing: .5px; color: $gold; font-size: 1.28571429rem; line-height: 1.68571429rem; margin: 0 0 10px 0;}
                            & p {color: $black; line-height: 24px; letter-spacing: 1px; margin: 0;
                                & span {color: $black; letter-spacing: 1px; margin: 0;}
                                &.piece-qty {color: $red-portugal; letter-spacing: 1px;}
                                &.detail {width: max-content; margin: 8px 0 0 5px;
                                    & .single-capacity {width: max-content; background: #ddd; border-radius: 8px; padding: 2px 5px;}
                                }
                            }
                        }
                        & .capacity {
                            & span {font-family: $oswald; letter-spacing: .5px; justify-self: end; text-transform: uppercase; color: $gold; font-size: 18px; line-height: 1.28571429rem; -webkit-text-stroke: 1.1px; letter-spacing: .5px;}
                        }
                        & .price {display: grid; grid-template-columns: 1fr; gap: 5px;
                            & span {font-family: $oswald; letter-spacing: .5px; color: $gold; line-height: 1.28571429rem; -webkit-text-stroke: 1.1px; letter-spacing: .5px;}
                        }
                    }
                }
            }
        }
    }
    & .block-buttons {margin: 100px 0 150px;
        & a {z-index: 1; display: inline-block; color: $gold; background-color: $white; border: .5px solid $gold; border-radius: 8px; box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 6px 6px 6px rgba(0, 0, 0, 0.23); width: 320px; height: max-content; margin: 0 50px; padding: 10px 20px; transition: all 0.5s ease; overflow: hidden;
            &:hover {color: $black; border: .5px solid $black; box-shadow: none;}
        }
    }
    & #blockSocial {margin: 0;}
}

@media (max-width: 767.98px) {
    #pages[data-route="grocery"] {padding: 80px 0;
        & .block-first {grid-template-columns: 1fr; margin: 0; padding: 0 5%; gap: 0px;
            & .banner-grocery {order: 2; width: 80%; height: 270px;}
            & .right-side {order: 1; padding: 0;
                & .address-schedules {order: 2;
                    & .detail a {width: max-content;}
                }
                & h1 {order: 1; margin-left: 0;
                    & svg {width: 40px;}
                    & .two-lines span {font-size: 11.5vw; line-height: 11.5vw;}
                }
            }
        }
        & .block-second {grid-template-columns: 1fr;
            & .slogan {order: 2; padding-bottom: 120px;
                & .container {width: 220px; margin-left: 30px;}
            }
            & img {order: 1; width: 85%; margin: 30px 10%;}
        }
        & .block-reassurance {grid-template-columns: 1fr; height: fit-content; margin: 50px 0 30px;
            & .left {
                & .background-gold {top: -470px; left: 50px; width: 280px; height: 650px;}
                & .left-picture {top: 130px; width: 80%; left: 5%;}
                & .right-picture {top: -140px; bottom: initial; width: 70%; right: 5%;}
            }
            & .right {padding: 590px 5% 0 5%;
                & .block-dash {display: none;}
            }
        }
        & .block-department {padding: 0 5%;
            & h2 {width: max-content; margin: 0 auto 30px;
                & svg {display: none;}
                & span {font-size: 11vw;}
            }
            & .departments {grid-template-columns: 1fr;
                & .single-department img {border-radius: 36px;}
            }
        }
        & .block-menu {padding: 0 0 0 0!important;
            & h2 {justify-content: center; width: 100vw; margin: 0 auto 30px;
                & svg {display: none;}
                & span {font-size: 11vw;}
            }
            & .information {display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100vw; height: 150px; margin: 0!important; padding: 0 0 10px 0!important;
                & .block-left {display: flex; flex-direction: column; justify-content: center; align-items: center; margin-bottom: 5px;
                    & p:last-of-type {font-size: 18px; text-align: center; padding: 0 15% 0;}
                }
            }
            & .menus {gap: 25px; padding: 50px 25px 25px;
                & .azulejos {
                    &.top, &.bottom {height: 25px!important;}
                    &.left, &.right {width: 25px!important; height: calc(100% - 50px)!important;}
                }
                & .menu-detail {margin: 0 auto!important;
                    & .dish-single .top-line {display: flex; flex-wrap: wrap; gap: 10px; width: calc(100vw - 65px);
                        & .help-block {width: 15px;
                            & .modal-preview {top: 60px; left: 0; width: 100vw;
                                & .information {display: none;}
                                & .content-block {grid-template-columns: 1fr;
                                    & .left-column {order: 2; margin-top: -120px; padding: 20px;
                                        & .close-button {position: absolute; top: 20px;}
                                        & .name {margin-bottom: 20px;}
                                    }
                                    & img {order: 1; width: calc(100% - 5%); border-radius: 25px; margin-top: 50px;}
                                }
                            }
                        }
                        & .dish-description {width: calc(100vw - 100px);
                            & p.detail {grid-template-columns: repeat(2, 110px); margin: 8px 0;
                                & .single-capacity {width: 100%; text-align: center;}
                            }
                        }
                        & .capacity {width: calc(100vw - 180px);}
                        & .price {width: 80px;}
                    }
                }
            }
        }
        & .block-buttons {flex-direction: column; margin: 50px 0 100px;
            & a {margin: 20px;}
        }
        & #blockSocial {padding: 0 5%;}
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {

}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
    #pages[data-route="grocery"] {padding: 60px 60px 0 300px;
        & h1 {
            & .two-lines span {font-size: 5vw; line-height: 6vw;}
        }
        & .block-first {gap: 30px;}
        & .block-second {grid-template-columns: 30% 1fr;
            & .container {align-self: flex-end;}
            & img {width: 100%;}
        }
        & .block-reassurance {
            & .left {
                & .background-gold {left: 20px; width: 280px; height: 750px;}
                & .left-picture {bottom: 120px;}
                & .right-picture {top: 70px; bottom: initial; left: 150px;}
            }
            & .right {padding: 100px 0 40px 10px;
                & .block-dash {left: -150px;}
                & .summary {
                    & p {font-size: 1.3vw; line-height: 1.8vw;}
                }
            }
        }
        & .block-menu {padding: 40px 0 0 0!important;
            & .menus .menu-detail .dish-single .top-line .help-block .modal-preview {
                & .content-block {height: calc(100vh - 180px); padding-top: 60px;
                    & .left-column {padding: 0 0 40px 40px;
                        & .close-button svg {height: 30px;}
                    }
                }
                & .information {margin: 0 !important;}
            }
            & .information {margin: 30px -60px 0!important; padding: 20px 60px 20px 60px!important;}
        }
    }
}

@media screen and (min-width: 1900px) {
    #pages[data-route="grocery"] {padding: 60px 0 0 240px;
        & .block-first {grid-template-columns: 50% 1fr; margin-left: 0;
            & .banner-grocery {height: 760px;}
            & .right-side {width: max-content;
                & h1 {top: 40px; margin-left: -320px;
                    & .two-lines span {font-size: 115px; line-height: 115px;}
                }
                & .address-schedules {font-size: 24px; line-height: 30px;
                    & .detail .line-day {grid-template-columns: 100px 1fr;
                        & .right .schedule .schedule-single {width: 60px;}
                    }
                }
            }
        }
        & .block-second {
            & img {width: 80%; margin-left: -240px;}
            & .slogan {padding-bottom: 180px;
                & .container {width: 250px; font-size: 27px; line-height: 35px;}
            }
        }
        & .block-reassurance {grid-template-columns: 55% 45%; height: 1080px; padding: 0 10%;
            & .left {
                & .background-gold {top: -730px; width: 330px; height: 1000px;}
                & .left-picture {top: 230px; width: 50%;}
                & .right-picture {top: 150px; left: 440px; width: 55%;}
            }
            & .right {padding: 300px 0 40px 40px;
                & h2 {font-size: 36px;}
                & .block-dash {top: 320px;}
                & .summary {width: 370px;
                    & p {font-size: 20px;}
                }
            }
        }
        & .block-department {padding: 60px 20% 0 20%;
            & h2 {width: 350px;
                & span {font-size: 60px; line-height: 60px;}
            }
        }
        & .block-menu {padding: 60px 0;
            & h2 {width: 590px; margin-left: 20%;
                & span {font-size: 60px; line-height: 60px;}
            }
            & .information {margin: 30px -60px 0!important; padding: 20px 25% 20px 25%!important;}
            & .menus {margin: 0 20%; padding: 40px 0 50px;
                & .menu-detail {width: calc(100% - 240px)!important;
                    & .dish-single .top-line .help-block .modal-preview {
                        & .information {width: calc(100vw - 180px)!important; margin: 0 -30px!important;}
                        & .content-block .left-column .modal-detail {grid-template-columns: repeat(4, 150px);}
                    }
                }
            }
        }
        & #blockSocial {margin: 120px 27% 0;}
    }
}
