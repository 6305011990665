footer {background: $black; display: flex; flex-direction: column; padding: 50px 10% 0 360px;
    & .top-footer {display: grid; grid-template-columns: repeat(3, 1fr); gap: 20px; padding-bottom: 30px;
        & .column {
            &.left {display: flex; flex-direction: column; justify-content: flex-end;
                & a {width: max-content; color: $white; font-size: 18px; font-weight: 500; margin: 5px; transition: $transition-hover;
                    &:hover {color: $gold; transition: $transition-hover;}
                }
            }
            &.middle {width: 200px;}
            &.right {display: flex; flex-direction: column; justify-content: flex-end; align-items: flex-end;
                & img {width: 140px; height: auto; margin-bottom: 10px}
                & .footer-links {display: flex; flex-direction: column; align-items: flex-end;
                    & a {display: flex; justify-content: flex-end; align-items: center; width: max-content; margin: 5px 0;
                        & span {color: $white; font-size: 22px; font-weight: 600; transition: $transition-hover;}
                        &:hover {
                            & span {cursor: pointer; color: $gold;}
                        }
                    }
                }
            }
        }
    }
    & .bottom-footer {display: grid; grid-template-columns: max-content 1fr; align-items: center; background: $black; margin: 0 calc(-10% - 30px); padding: 20px 5%;
        & .footer-creatis-web-art {justify-self: start; display: flex; justify-content: center; align-items: center; text-align: center; margin-right: 20px;
            & .footer-line {cursor: default; color: #898989; line-height: 26px;}
            & a {position: relative; width: max-content; color: $gold; font-size: 16px; margin-left: 8px; -webkit-transition: color .2s; -o-transition: color .2s; transition: color .2s;
                &:before {position: absolute; bottom: 5px; left: 0; display: block; width: 0; margin-bottom: -.5rem; content: ""; height: 1px; -webkit-transition: width 0s ease,background-color .3s ease; -o-transition: width 0s ease,background-color .3s ease; transition: width 0s ease, background-color .3s ease;}
                &:after {position: absolute; right: 0; bottom: 5px; display: block; width: 0; margin-bottom: -.5rem; background-color: currentColor; content: ""; height: 1px; -webkit-transition: width .3s ease; -o-transition: width .3s ease; transition: width .3s ease;}
                &:hover:before {width: 100%; background-color: currentColor; -webkit-transition: width .3s ease; -o-transition: width .3s ease; transition: width .3s ease;}
                &:hover:after {width: 100%; background-color: rgba(0,0,0,0); -webkit-transition: all 0s ease; -o-transition: all 0s ease; transition: all 0s ease;}
            }
            & #footer-block-svg {z-index: 98; display: flex; justify-content: center; align-items: center; padding-left: 30px; height: 40px;
                & svg {height: 70px;
                    &#footer-cwa {position: absolute; width: 40px; height: 40px;}
                    &#footer-circle-cwa {position: absolute; width: 47px; height: 47px; -webkit-animation:spin 15s linear infinite; -moz-animation:spin 15s linear infinite; animation:spin 15s linear infinite;
                        @-moz-keyframes spin {100% {-moz-transform: rotate(360deg);}}
                        @-webkit-keyframes spin {100% {-webkit-transform: rotate(360deg);}}
                        @keyframes spin {100% {-webkit-transform: rotate(360deg); transform:rotate(360deg);}}
                        @keyframes spin {100% {transform: rotate(360deg); transform:rotate(360deg);}}
                    }
                    &#footer-cwa .st0 {fill: $gold;}
                    &#footer-circle-cwa .st0 {fill: $gold;}
                }
            }
        }
        & .copyright {cursor: default; color: #898989; text-align: right;}
    }
}

@media (max-width: 767.98px) or ((min-width : 768px) and (max-width : 1280px) and (orientation : portrait)) {
    footer {padding: 50px 20px 20px;
        & .top-footer {grid-template-columns: 1fr;
            & .column {
                &.left {align-items: center;
                    & a {font-size: 5vw; line-height: 6vw;}
                }
                &.right {align-items: center;
                    & .footer-links {align-items: center;}
                }
            }
        }
        & .bottom-footer {grid-template-columns: 1fr; margin: 0;
            & .footer-creatis-web-art {order: 2; display: grid; grid-template-columns: max-content max-content; justify-content: center; margin: 30px auto 0;
                & #footer-block-svg {grid-area: 2 / 1 / span 1 / span 2; padding: 0; margin-top: 10px;}
            }
            & .copyright {order: 1; display: flex; flex-direction: column; width: 80vw; text-align: center; line-height: 24px; margin: 0 auto;}
        }
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {
    footer .bottom-footer .copyright span.not-mobile {display: none;}
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {

}

@media screen and (min-width: 1900px) {
    footer {padding: 50px 30% 0 30%;
        & .top-footer .column.left a {font-size: 20px; line-height: 27px;}
        & .bottom-footer {font-size: 20px;}
    }
}
