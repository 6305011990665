.grecaptcha-badge {z-index: -1;}

#header-top {z-index: 999; width: 100vw; height: 60px; background: $black; padding: 0 30px;
    & .header-link {
        & a {display: flex;
            & svg {width: 20px; height: 20px; margin: 0;
                & path {fill: $white; transition: .4s;}
                &:hover path {fill: $gold; transition: .4s;}
            }
            &.phone {display: inline-block; overflow: hidden; font-family: $charm; color: $white; letter-spacing: 3px; border: 1px solid $white; transition: border-color .4s,color .4s,background-color .4s; white-space: nowrap; padding: 10px 15px;
                &::after {content: ""; z-index: -1; position: absolute; top: 0; right: 0; bottom: 0; left: 0; opacity: 0; display: block; background-color: #856b39; -webkit-transform: translateY(100%); transform: translateY(100%); transition: opacity 0s .4s,background-color .4s,-webkit-transform .4s cubic-bezier(.77,0,.175,1); transition: transform .4s cubic-bezier(.77,0,.175,1),opacity 0s .4s,background-color .4s; transition: transform .4s cubic-bezier(.77,0,.175,1),opacity 0s .4s,background-color .4s,-webkit-transform .4s cubic-bezier(.77,0,.175,1); width: 100%;}
                &:hover {color: $black; -webkit-text-stroke-width: 1px; border: 1px solid $gold;
                    &::after {opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); transition: opacity .4s,-webkit-transform 0s; transition: transform 0s,opacity .4s; transition: transform 0s,opacity .4s,-webkit-transform 0s;}
                }
            }
        }
    }
}

#blockSocial {position: relative; display: grid; grid-template-columns: 27% 1fr 40%; margin: 150px 80px 0; padding-bottom: 80px;
    & .column-left {position: absolute; top: 0;
        & img {width: 210px; height: auto; box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 6px 6px 6px rgba(0, 0, 0, .23);}
    }
    & .column-middle {z-index: 1; width: 640px; height: 320px; background: #232323; margin: 90px auto 20px 50%;
        & .stars {text-align: center; margin: -30px 0 20px;
            & svg {width: 50px;
                & path {fill: $gold;}
                &.big-star {width: 70px; margin: -80px 15px 5px;}
            }
        }
        & .summary {
            & h2 {font-family: $oswald; letter-spacing: .5px; color: #856b39; font-size: 36px; text-align: center; margin-bottom: 20px;}
            & p {width: 65%; color: $white; font-size: 21px; line-height: 24px; letter-spacing: 1px; text-shadow: 0 .0625vw .25vw rgba(0,0,0,.5); text-align: center; margin: 0 auto;}
            & .links {display: flex; justify-content: center; align-items: center; margin-top: 30px;
                & a {margin: 0 15px;
                    & svg {transition: .4s;
                        & path {fill: $gold; transition: .4s;}
                        &.instagram {height: 50px;}
                        &.facebook {height: 40px;}
                        &:hover {transform: scale(1.1); transition: .4s;
                            & path {fill: $white; transition: .4s;}
                        }
                    }
                }
            }
        }
    }
    & .column-right {
        & .background-gold {position: absolute; top: 0; right: 15%; background: #ad9a74; width: 200px; height: 200px;}
        & img {position: absolute; bottom: 80px; right: 0; width: 420px; height: auto; box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 6px 6px 6px rgba(0, 0, 0, .23);}
    }
}

#pages {padding: 60px 0 0 240px;
    & .banner {
        & h1 {color: $black; font-size: 5vw; font-weight: 700; padding-top: 100px;}
    }
    & a.link-animated {position: relative; width: max-content; color: $red-portugal; -webkit-transition: color .2s; -o-transition: color .2s; transition: color .2s;
        &:before {position: absolute; bottom: 5px; left: 0; display: block; width: 0; margin-bottom: -.5rem; content: ""; height: 1px; -webkit-transition: width 0s ease,background-color .3s ease; -o-transition: width 0s ease,background-color .3s ease; transition: width 0s ease, background-color .3s ease;}
        &:after {position: absolute; right: 0; bottom: 5px; display: block; width: 0; margin-bottom: -.5rem; background-color: currentColor; content: ""; height: 1px; -webkit-transition: width .3s ease; -o-transition: width .3s ease; transition: width .3s ease;}
        &:hover:before {width: 100%; background-color: currentColor; -webkit-transition: width .3s ease; -o-transition: width .3s ease; transition: width .3s ease;}
        &:hover:after {width: 100%; background-color: rgba(0,0,0,0); -webkit-transition: all 0s ease; -o-transition: all 0s ease; transition: all 0s ease;}
    }
    & #breadcrumb {margin-top: 50px;
        & a {color: $red-portugal;
            &.home-link {width: 20px; height: 20px;
                &:hover {cursor: pointer;}
            }
            &.link {display: flex; align-items: inherit; height: 20px;
                &:hover {cursor: pointer; text-decoration: underline;}
                & span:hover {cursor: pointer;}
            }
        }
        & .separator {margin: 0 5px;}
    }
    &.page-actuality-index {padding-top: 159px;}
}

@media (max-width: 767.98px) {
    #pages {padding: 60px 0 0;
        & #blockSocial {margin: 100px 20px 0; padding: 0;
            & .column-left, .column-right {display: none;}
            & .column-middle {width: 90vw; height: auto; margin: 20px auto 20px;
                & .summary .links {padding-bottom: 50px;}
            }
        }
        & #breadcrumb {
            & .separator:nth-of-type(2) {display: none;}
            & .current-page.show {display: none;}
        }
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {
    #pages {padding: 60px 0 0 0;}
    #blockSocial {margin: 150px 30px 0;
        & .column-middle {width: 55vw; margin: 90px auto 20px 70%;
            & .summary {
                & h2 {margin: 60px auto 0;}
                & p {width: 85%;}
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
    #blockSocial {
        & .column-middle {width: 480px;
            & .summary {
                & h2 {margin-top: 40px; margin-bottom: 20px;}
                & p {width: 100%;}
            }
        }
    }
}

@media screen and (min-width: 1900px) {
}
