#pages {
    & .introduction-line {display: grid; grid-template-columns: max-content 1fr; gap: 50px; background: $white; padding: 40px; margin: -40px;
        & h1 {font-family: $charm; color: $black; font-size: 77px; line-height: 77px; font-weight: 600;}
        & .right {justify-self: end; align-self: end;
            & .search-box {width: 100%; display: flex; justify-content: center; align-items: center; flex-wrap: wrap; margin: 15px auto 0;
                & button.category {display: inline-block; text-decoration: none; background-color: $white; border: 3px solid $white; border-radius: 4px; font-size: 21px; margin: .5rem .5rem 0 0; padding: 5px 10px;
                    &:hover, &.is-checked {cursor: pointer; color: $red-portugal; border-bottom: 3px solid $red-portugal;}
                }
            }
            & .block-quicksearch {display: flex; justify-content: space-between; align-items: center; width: 600px; background-color: #f5f5f5; border: 1px solid #ddd; border-radius: 25px; margin: 20px auto 0 auto; padding: 7px 20px;
                & #quicksearch {width: 100%; background-color: #f5f5f5; border-color: transparent; outline: none;}
                & svg {fill: #818181; width: 20px; height: 20px;}
            }
        }
    }
    &[data-route="legal_notice"], &[data-route="privacy"] {background: $nude; padding: 100px 40px 50px 280px;
        & .content {display: grid; grid-template-columns: 1fr; font-size: 16px; padding: 70px 50px 30px 30px;
            & h2 {font-family: $oswald; letter-spacing: .5px; font-size: 27px; margin: 20px 0;}
            & h3 {font-family: $oswald; letter-spacing: .5px; font-size: 27px;}
            & p, & li, .not-bold {font-size: 18px; line-height: 27px; margin: 5px 0;}
            & .contact-address {border-left: 5px solid $red-portugal; margin: 20px 0 20px 50px;
                & p {font-size: 18px; line-height: 27px; margin: 0; padding-left: 10px;}
            }
        }
        & .update {font-size: 16px; text-align: right; padding: 20px 50px;}
    }
    &.page-error {background: $nude; padding: 60px 0 0 240px;
        & .content {min-height: 100vh;
            & img {width: 40vw!important;}
        }
        & h1 {color: #D04A3A; font-size: 24px; text-align: center; margin: 10px 0 20px;
            & span {font-size: 50px; line-height: 77px;}
        }
        & .block-error-image {display: flex; justify-content: center;
            & img {width: 40%;}
        }
        & .block-error-button {display: flex; justify-content: center; margin-top: 30px;
            & a {background-color: $gold; border: 3px solid $gold; border-radius: 25px; color: $white; font-size: 24px; font-weight: 700; padding: 10px 20px;
                &:hover {background-color: $white; color: $gold;}
            }
        }
        &.network {margin-bottom: -15px; padding: 20px 0 0;
            & .block-error-logo {margin-bottom: 50px;
                & img {width: auto; height: 80px;}
            }
            & .block-error-title {width: max-content; background-color: $red-portugal; border-radius: 50px; margin: 40px auto;
                & h1 {color: $white; font-size: 27px; font-weight: 800; text-align: center; animation-duration: 2.5s; padding: 10px 20px; animation-name: clignotement; animation-iteration-count: infinite; transition: none;}
                @keyframes clignotement {
                    0%   {opacity:1;}
                    50%  {opacity:0;}
                    100% {opacity:1;}
                }
            }
            & h2 {color: $red-portugal; font-size: 24px; text-align: center; margin: 10px 0 20px;
                & span {font-size: 50px; line-height: 77px;}
            }
            & .svg-animated-container {
                /** SVG ANIMATED - START **/
                    .sf-toolbarreset {display: none;}
                    .st_woman0{fill:#F7F7F7;}
                    .st_woman1{fill:#202020;}
                    .st_woman2{fill:#E5E5E5;}
                    .st_woman3{fill:#F9F4F4;}
                    .st_woman4{opacity:0.8; fill:#F2F2F2;}
                    .st_woman5{fill:#E7D2D6;}
                    .st_woman6{fill:#F5EDF0;}
                    .st_woman7{fill:#551519;}
                    .st_woman8{fill:#136926;}
                    .st_woman9{fill:#FFFFFF;}
                    .st_woman10{fill:#FFB281;}
                    .st_woman11{fill:#6FAACC;}
                    .st_woman12{fill:#FF9469;}
                    .st_woman13{fill:#2BC8F5;}
                    .st_woman14{fill:#EAF3FA;}
                    .st_woman15{fill:#3A3A3A;}
                    .st_woman16{fill:#FFFA87;}
                    .st_woman17{fill:#E97F62;}
                    .st_woman18{fill:#E87F62;}
                    .st_woman19{fill:#337BAF;}
                    .st_woman20{fill:#FDFAFA;}
                    .svgrelative {position: relative;}
                    .mainimagewrapper {display: flex; flex-direction: column; justify-content: center; align-items: center; margin-top: 20px; box-shadow: 0px 50px 50px #a3a3a38a, -12px -12px 24px $nude;}
                    #XMLID_8953_ {transform-origin: 224px 139px; animation: 1s womanHlebalo infinite alternate ease-in-out;}
                    #XMLID_8566_ {transform-origin: 211px 155px; animation: 1.5s womanMapHand infinite alternate ease-in-out;}
                    #XMLID_9961_ {transform-origin: 226.30294799804688px 157.3000030517578px; animation: 1s womanPointHand infinite alternate ease-in-out;}
                    #XMLID_1691_ {transform-origin: 483.5px 127.30000305175781px; animation: 1s painterTool infinite alternate ease-in-out;}
                    #XMLID_1510_ {transform-origin: 424.3625183105469px 270.3000183105469px; animation: 1s painterTorso infinite alternate ease-in-out;}
                    #XMLID_327_, #XMLID_1737_, #XMLID_1678_ {transform-origin: 155.88100677490235px 134.64999771118164px; animation: 1s painterRhand infinite alternate ease-in-out;}
                    #XMLID_3327_ {transform-origin: 594.368225px 149.800003px; animation: 2s hammerWorkerleft infinite alternate ease-in-out;}
                    #XMLID_1819_ {transform-origin: 560.059998px 153.600006px; animation: 2s hammerWorkerHandAndHammer infinite alternate ease-in-out;}
                    #XMLID_2191_ {transform-origin: 614.362548828125px 201.1999969482422px; animation: 2s hammerWorkerTorso infinite alternate ease-in-out;}
                    #XMLID_10185_, #XMLID_8555_, #XMLID_8545_, #XMLID_8560_, #XMLID_10182_ {fill: #ff8300;}
                    #worker2_level {transform-origin: 333px 143px; animation: 1s levelMove infinite alternate ease-in-out;}
                    #worker4_hammer {transform-origin: 608.4356079101562px 173.29998779296875px; animation: 2s hammerHit infinite alternate ease-in-out;}
                    @keyframes womanHlebalo {
                        0% {transform: scale(1, 1)}
                        20% {transform: scale(0.9, 0.2)}
                        40% {transform: scale(1.05, 1)}
                        80% {transform: scale(0.95, 0.2)}
                        100% {transform: scale(1.05, 1)}
                    }
                    @keyframes womanMapHand {
                        0% {transform: scale(1, 1) rotateZ(-28deg)}
                        20% {transform: scale(0.99, 1) rotateZ(0deg)}
                        40% {transform: scale(1.01, 1) rotateZ(-48deg)}
                        80% {transform: scale(0.98, 1) rotateZ(0deg)}
                        100% {transform: scale(1.01, 1) rotateZ(-28deg)}
                    }
                    @keyframes womanPointHand {
                        0% {transform: scale(1, 1)}
                        20% {transform: scale(0.9, 1)}
                        40% {transform: scale(1.05, 1)}
                        80% {transform: scale(0.95, 1)}
                        100% {transform: scale(1.05, 1)}
                    }
                    @keyframes painterTorso {
                        0% {transform: scaleY(1)}
                        20% {transform: scaleY(0.98)}
                        40% {transform: scaleY(0.99)}
                        80% {transform: scaleY(1.01)}
                        100% {transform: scaleY(1)}
                    }
                    @keyframes painterTool {
                        0% {transform: translateY(0)}
                        20% {transform: translateY(4px)}
                        40% {transform: translateY(2px)}
                        80% {transform: translateY(-2px)}
                        100% {transform: translateY(1px)}
                    }
                    @keyframes painterRhand {
                        0% {transform: translateY(0)}
                        20% {transform: translateY(4px)}
                        40% {transform: translateY(2px)}
                        80% {transform: translateY(-2px)}
                        100% {transform: translateY(1px)}
                    }
                    @keyframes hammerWorkerleft {
                        0% {transform: translate(-7px, -2px) rotateZ(-29deg) scaleX(0.99);}
                        20% {transform: translate(-7px, -2px) rotateZ(-29deg) scaleX(0.98);}
                        40% {transform: translate(-7px, -2px) rotateZ(-29deg) scaleX(0.99);}
                        80% {transform: translate(-7px, -2px) rotateZ(-29deg) scaleX(0.98);}
                        100% {transform: translate(-7px, -2px) rotateZ(-29deg) scaleX(0.99);}
                    }
                    @keyframes hammerWorkerHandAndHammer {
                        0% {transform: rotateZ(0deg);}
                        25% {transform: rotateZ(0deg);}
                        50% {transform: rotateZ(0deg);}
                        80% {transform: rotateZ(-30deg);}
                        100% {transform: rotateZ(0deg);}
                    }
                    @keyframes levelMove {
                        0% {transform: rotateZ(-2deg);}
                        50% {transform: rotateZ(0deg);}
                        100% {transform: rotateZ(2deg);}
                    }
                    @keyframes hammerWorkerTorso {
                        0% {transform: translateY(0px) translateX(0px) rotateZ(2deg);}
                        25% {transform: translateY(0px) translateX(0px) rotateZ(0deg);}
                        50% {transform: translateY(0px) translateX(0px) rotateZ(1deg);}
                        80% {transform: translateY(0px) translateX(0px) rotateZ(-7deg);}
                        100% {transform: translateY(0px) translateX(0px) rotateZ(1deg);}
                    }
                    @keyframes hammerHit {
                        0% {transform: translateY(1px) translateX(3px) rotateZ(0deg);}
                        25% {transform: translateY(1px) translateX(1px) rotateZ(20deg);}
                        50% {transform: translateY(1px) translateX(-1px) rotateZ(6deg);}
                        80% {transform: translateY(1px) translateX(-1px) rotateZ(-20deg);}
                        100% {transform: translateY(1px) translateX(-1px) rotateZ(10deg);}
                    }
                    .mtssectionproblems {height: 100vh; display: flex;}
                    .mtssectionproblems .container {justify-content: center; align-items: center; display: flex;}
                    .mtssectionproblems .mtslogowrapper {width: 100%; text-align: center; display: block; margin-bottom: 9%;}
                    .mtssectionproblems .problemheading {font-family: 'Futura New'; font-size: 44px; font-weight: bold; line-height: 48px; letter-spacing: 0px; text-align: left; color: #000; margin-top: 2%; margin-bottom: 25px;}
                    .problemphone {margin-bottom: 15%;}
                    .probwrapper {width: 100%;}
                    .mainimagewrapper svg {width: 800px; height: 300px;}
                    .mtssectionproblems .problemphone a {font-family: 'Futura New'; font-size: 30px; letter-spacing: -1px; text-align: left; color: #000; text-decoration: none;}
                    .mtssectionproblems .problemphone p {font-size: 16px; color: #aaa;}
                    .mtssectionproblems .mts2-footer__soc-icons {margin-right: 30px;}
                    .mtssectionproblems .vk, .mtssectionproblems .tw, .mtssectionproblems .fb, .mtssectionproblems .insta, .mtssectionproblems .ok {fill: #EA212E; transition: 300ms all ease;}
                    .mtssectionproblems .vk:hover, .mtssectionproblems .tw:hover, .mtssectionproblems .fb:hover, .mtssectionproblems .insta:hover, .mtssectionproblems .ok:hover {fill: #c30d14;}
                /** SVG ANIMATED - END **/
            }
        }
    }
}

@media (max-width: 767.98px) {
    #pages {
        & .introduction-line {grid-template-columns: 1fr; padding: 20px; margin: 0;
            & h1 {font-size: 12vw; line-height: 13vw;}
        }
    }
    #pages[data-route="legal_notice"], #pages[data-route=privacy] {padding: 60px 0 50px 0;
        & .content {
            & h2 {font-size: 6vw; line-height: 8vw;}
            & ul {padding-left: 30px;}
            & .contact-address {margin: 20px 0 20px 20px;}
        }
        & .update {text-align: center; padding: 20px;}
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {
    #pages[data-route="legal_notice"], #pages[data-route="privacy"] {padding: 100px 40px 50px 40px;}
    #pages .introduction-line h1 {font-size: 7vw;}
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
    #pages .introduction-line .right .search-box button.category {font-size: 1.2vw;}
}

@media screen and (min-width: 1900px) {
    #pages {
        & .content {font-size: 20px; line-height: 1.4285em; padding: 100px 20% 40px 10%;
        }
        &.page-legal-notice {
            & h2 {font-size: 32px;}
            & .introduction-line {padding: 40px 5% 40px;}
            & .update {font-size: 20px; padding: 0 20% 40px;}
        }
        &.page-privacy {
            & h2 {font-size: 32px;}
            & .introduction-line {padding: 40px 5% 40px;}
        }
        &.page-about-us {padding-bottom: 90px;
            & .banner-block {
                & img {height: 800px;}
                & .banner {height: 900px;}
            }
            & .content-block {padding: 30px 10%;
                & .first-block {
                    & .right-block p {width: 80%; font-size: 20px;}
                }
                & .second-block .team-picture {width: calc(80% - 240px); height: 800px;}
            }
        }
    }
}
