#pages {
    &[data-route="actuality_index"] {background: $nude; padding: 100px 40px 50px 280px;
        & h1 {font-family: $charm; color: black; line-height: 77px;}
        & .introduction-line {background: white; padding: 40px; margin: -40px;
            & .right {
                & .search-box {width: 100%; margin: 15px auto 0;
                    & button.category {display: inline-block; text-decoration: none; background-color: #eee; border: 3px solid #eee; border-radius: 4px; color: black; margin: .5rem .5rem 0 0; padding: 5px 10px;
                        &:hover, &.is-checked {cursor: pointer; color: $red-portugal; border-bottom: 3px solid $red-portugal;}
                    }
                }
            }
        }
        & .content {padding: 70px 0;
            & .block-articles {
                & a.article {width: 30%; border-radius: 15px; transition: $transition-hover; margin-bottom: 20px;
                    & img {width: 100%; height: 210px; border-radius: 15px 15px 0 0;}
                    & .informations {height: 150px; background: white; border: 1px solid white; border-radius: 0 0 15px 15px; padding: 10px 20px 20px;
                        & .article-title {cursor: pointer; width: calc(100% - 20px); color: black; margin-top: 10px; transition: $transition-hover;}
                        & .article-date {color: black;}
                    }
                    &:hover {cursor: pointer; box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, .2); transition: $transition-hover;
                        & .article-title {cursor: pointer; transition: $transition-hover;}
                    }
                }
            }
        }
    }
    &[data-route="actuality_show"] {background: $nude; padding: 0 0 0 240px;
        &:has(#article-2024-02-24) {
            & h1 {width: 60%;}
        }
        & .content {min-height: 65vh; background: white; padding: 60px 0 240px;
            & h1 {max-width: 900px; font-family: $oswald; color: $gold; letter-spacing: .5px; line-height: 50px; margin: 40px auto 10px;}
            & .article-sub-title {font-family: $oswald; color: black; letter-spacing: .5px; margin: 10px 0 20px;
            }
        }
        & .banner-block {margin-top: -240px;
            & .banner-img {width: auto; height: 60vh; border-radius: 8px; box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 6px 6px 6px rgba(0, 0, 0, .23); margin-top: 20px;
                &.mobile {display: none;}
            }
        }
        & .article-content-block, .credits {margin-top: -270px; padding: 270px 10% 0;
            & .top-column {width: calc(100% - 20px); padding: 50px 5%;
                & h2 {font-family: $oswald; font-size: 24px; margin: 20px 0;}
                & h3 {font-family: $oswald; font-size: 20px; font-weight: 800;}
                & p {font-family: $oswald; font-size: 18px; line-height: 27px; margin: 15px 0; padding-left: 5px;}
                & ul, & ol {margin-left: 30px;
                    & li {line-height: 24px; margin-bottom: 10px;
                        & div {font-weight: 400;}
                    }
                }
                & a {overflow-wrap: break-word; color: $red-portugal; font-weight: 600; text-decoration: none; background-position-y: -0%; background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, transparent), color-stop(50%, #C5590F36)); background-image: -o-linear-gradient(transparent 50%, #C5590F36 50%); background-image: linear-gradient(transparent 50%, #C5590F36 50%); background-size: auto 175%; -webkit-transition: background 500ms ease; -o-transition: background 500ms ease; transition: background 500ms ease; padding: 3px 5px 0px;
                    &:hover {background-position-y: 100%; background-image: linear-gradient(transparent 50%, #C5590F36 50%);}
                }
                & .youtube_player {min-height: 400px; height: 400px;}
            }
        }
        & .credits {width: calc(100% - 20px); padding: 200px 10% 0;
            & p {font-size: 18px; line-height: 27px; margin: 15px 0; padding-left: 5px;}
            & ul, & ol {margin-left: 30px;
                & li {font-size: 18px; line-height: 27px; margin-bottom: 10px;
                    & div {font-weight: 400;}
                }
            }
            & a {overflow-wrap: break-word; color: $red-portugal; font-weight: 600; text-decoration: none; background-position-y: -0%; background-image: -webkit-gradient(linear, left top, left bottom, color-stop(50%, transparent), color-stop(50%, #C5590F36)); background-image: -o-linear-gradient(transparent 50%, #C5590F36 50%); background-image: linear-gradient(transparent 50%, #C5590F36 50%); background-size: auto 175%; -webkit-transition: background 500ms ease; -o-transition: background 500ms ease; transition: background 500ms ease; padding: 3px 5px 0px;
                &:hover {background-position-y: 100%; background-image: linear-gradient(transparent 50%, #C5590F36 50%);}
            }
        }
        & .block-all-pictures {padding: 2rem 0;
            & h2 {font-family: $oswald; color: $gold; opacity: .6; font-size: 6vw;}
            & .pictures {width: calc(100% - 4rem); margin: 2rem;
                & .title {background: $light-color; width: max-content; margin: 0 auto 2rem; padding: 10px 20px;}
                & a {width: auto;
                    & img {width: auto; height: auto; max-height: 245px; border-radius: 25px;}
                }
                & video {width: auto; height: auto; max-height: 315px; border-radius: 25px;}
            }
        }
        & .last-articles-block {padding: 0 5%;
            & .block-title {position: relative;
                & h2 {position: relative; z-index: 1; font-family: $oswald; color: $gold; font-size: 32px; line-height: 69px; letter-spacing: .5px; font-weight: 800;}
                & .border-block {position: relative; z-index: 0; background: $red-portugal; opacity: .21; width: 300px; height: 20px; margin-top: -30px; margin-left: 10px;}
            }
            & .last-articles {display: grid; grid-template-columns: repeat(3, 1fr); gap: 20px; margin-top: 30px; padding: 20px;
                & a.last-single {width: 100%; border-radius: 15px; transition: $transition-hover;
                    & img {width: 100%; height: 210px; border-radius: 15px 15px 0 0;}
                    & .informations {height: 150px; background: white; border: 1px solid white; border-radius: 0 0 15px 15px; padding: 10px 20px 20px;
                        & .article-title {cursor: pointer; width: calc(100% - 20px); color: black; margin-top: 10px; transition: $transition-hover;}
                        & .article-date {color: black;}
                    }
                    &:hover {cursor: pointer; box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, .2); transition: $transition-hover;
                        & .article-title {cursor: pointer; transition: $transition-hover;}
                    }
                }
            }
            & .block-button {margin-top: 50px;
                & a {z-index: 1; display: inline-block; color: black; background-color: white; border: .5px solid #212121; border-radius: 8px; width: max-content; height: max-content; padding: 10px 20px; transition: all 0.5s ease; overflow: hidden;
                    &:after {position: absolute; content: ""; width: 0; height: 100%; top: 0; right: 0; z-index: -1; background-color: black; transition: all 0.5s ease;}
                    &:hover {color: white;}
                    &:hover:after {left: 0; width: 100%;}
                }
            }
        }
        & #blockSocial {margin: 100px 80px 0;}
        & #article-2023-07-27 {
            & #imgFirst {width: 90%!important; margin: 0 5% 20px;}
            & ul {margin-left: 0;}
            & #imgSecond {width: 90%!important; margin: 20px 5% 20px;}
        }
        & #article-2023-08-26 {
            & #imgFirst {width: 90%!important; margin: 0 5% 20px;}
        }
        & #article-2023-09-07 {
            & ul {margin-left: 0;}
        }
    }
}

@media (max-width: 767.98px) {
    #pages[data-route="actuality_index"] {padding: 60px 0 50px 0;
        & .introduction-line {grid-template-columns: 1fr; gap: 10px; padding: 20px; margin: 0;
            & h1 {font-size: 12vw; line-height: 13vw;}
            & .right {justify-self: center;
                & .search-box button.category {font-size: 3.5vw;}
            }
        }
        & .content {padding: 40px 0;
            & .block-articles {grid-template-columns: 1fr; justify-content: center;
                & a.article {left: 5%!important; width: 90%; margin: 0 auto 20px;}
            }
        }
    }
    #pages[data-route="actuality_show"] {position: relative; padding: 60px 0 50px 0;
        &:has(#article-2024-02-24) {
            & h1 {width: 100%;}
        }
        & .content {position: absolute; top: 60px; width: 100vw; min-height: calc(100vh - 60px); padding: calc(100vw + 50px) 0 0;
            & h1 {font-size: 9vw; line-height: 11vw; margin: 10px; padding: 0 20px;}
        }
        & .banner-block {position: absolute; top: 90px; margin-top: 0;
            & .banner-img {margin-top: 0;
                &.not-desktop {display: block; width: 90%; height: auto; border-radius: 0;}
            }
        }
        & .article-content-block {margin-top: 100vh; padding: 0 20px 0;
            & .top-column {width: 100%; padding: 0 0;}
        }
        & .credits {margin-top: 0; padding: 0 20px 0;
            & .top-column {width: 100%; padding: 0 0;}
        }
        & .block-all-pictures .pictures video {width: calc(100vw - 4rem);}
        & .last-articles-block {
            & .last-articles {grid-template-columns: 1fr; justify-content: center; padding: 0;
                & a.last-single {width: 100%; margin: 0 auto 20px;}
            }
        }
        & #blockSocial {margin: 100px 20px 0;}
        & #article-2023-08-26 {
            & #imgFirst {width: 90%!important; margin: 0 5% 20px;}
        }
        & #article-2024-02-24 {
            & ul {margin-left: 0;}
        }
        & #article-2024-03-20 {
            & img {width: calc(100vw - 100px); margin-bottom: 50px;}
        }
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {
    #pages[data-route="actuality_index"] {padding: 100px 40px 50px 40px;
        & .introduction-line .right .search-box button.category {font-size: 1.6vw;}
        & .content .block-articles {grid-template-columns: repeat(2, 49%);
            & a.article {width: 48%;
                & .informations .article-title {font-size: 2.4vw;}
            }
        }
    }
    #pages[data-route="actuality_show"] {padding: 0;
        & .content {min-height: 45vh; padding: 60px 40px 0;
            & h1 {padding: 0 10%;}
        }
        & .banner-block {margin-top: -240px;
            & .banner-img {height: 40vh;}
        }
        & .article-content-block {padding: 270px 40px 0;}
        & .credits {padding: 180px 40px 0;}
        & .last-articles-block .last-articles {grid-template-columns: repeat(2, 1fr);
            & a.last-single .informations .article-title {font-size: 2.4vw;}
        }
        & .block-all-pictures .pictures video {max-height: 205px;}
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
    #pages[data-route="actuality_index"] .content .block-articles a.article .informations .article-title {font-size: 1.6vw; line-height: 1.8vw;}
    #pages[data-route="actuality_show"] .last-articles-block .last-articles a.last-single .informations .article-title {font-size: 1.6vw; line-height: 1.8vw;}
}

@media screen and (min-width: 1900px) {
    #pages[data-route="actuality_index"] {
        & .introduction-line {padding: 40px 5% 40px;}
        & .content {padding: 70px 10%;
            & .block-articles a.article img {height: 420px;}
        }
    }
    #pages[data-route="actuality_show"] {
        & .banner-block {margin-top: -600px;}
        & .article-content-block .top-column p {font-size: 20px;}
        & .bottom-block {padding: 0 10% 50px;
            & .lastest-articles-block .lastest-articles {grid-template-columns: repeat(4, 1fr);}
        }
        & .article-content-block {padding: 270px 20% 0;}
        & .credits {padding: 270px 20% 0;}
        & .last-articles-block {padding: 0 20%;
            & .last-articles a.last-single img {height: auto;}
        }
        & #blockSocial {width: 45%; margin: 120px 27% 0;}
    }
}
