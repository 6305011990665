header {z-index: 1000; position: fixed; top: 0; width: 240px;
    & nav {height: 100vh; background: $black; padding: 10px 5%;
        & #menuNav {
            & .block-logo {
                & button {display: none;}
                & a {
                    & svg {width: 80%; height: auto; margin: 50px 10% 0 10%;
                        &:hover {cursor: pointer;}
                    }
                }
            }
            & .block-menu-links {margin: 180px 0 0 30px;
                & a {font-family: $charm; color: $white; line-height: 32px;
                    &:hover, &.active {color: $gold;}
                    &.active {-webkit-text-stroke: 1px;}
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    header {width: 100vw; height: 60px; background: $black;
        & nav {height: 60px; background: transparent;
            & #menuNav {
                & .block-logo {display: grid; grid-template-columns: max-content max-content; justify-content: space-between; align-items: center; width: 100%; background: $black; padding: 0;
                    & button {display: inline-flex; vertical-align: middle; position: relative; z-index: 0; border: 0; background-color: transparent; padding: 10px 0 0 0;
                        & #buttonMobileMenu {width: 40px; height: 30px; cursor: pointer; margin-right: 10px; position: relative; z-index: 1000000000;
                            & span {display: block; background: $white; height: 4px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; transition: all .4s;;
                                &.ham_75 {margin-bottom: 6px; width: 75%; transform: translateY(.5px);}
                                &.ham_0 {transition: none;}
                                &.ham_50 {margin-top: 4px; width: 50%; transform: translateY(1.5px);}
                            }
                        }
                    }
                    & a {
                        & svg {width: auto; height: 40px; margin: 0;}
                    }
                }
                &.mobile-menu-activate {
                    & .block-logo {
                        & button {
                            & #buttonMobileMenu {
                                & span {width: 100%; position: absolute; transition: all .4s;
                                    &.ham_75 {top: 8px; transform: translateY(1.5px) translateX(0) rotate(45deg);}
                                    &.ham_0 {transition: none; visibility: hidden;}
                                    &.ham_50 {bottom: 16px; transform: translateY(.5px) translateX(0) rotate(-45deg);}
                                }
                            }
                        }
                    }
                }
            }
        }
        & .mobile-menu-panel.not-desktop {width: 100vw; height: calc(100vh - 55px); background: $black; margin: -5px 0 0; padding: 0 50px; transform: translateX(-100%); transition: .3s;
            & .block-menu-links {
                & a {width: max-content; font-family: $charm; color: $white; line-height: 32px; -webkit-text-stroke: 1px;
                    &.active {color: $gold;}
                }
            }
            & .block-social-links {
                & a {display: flex;
                    & svg {width: 20px; height: 20px; margin: 0;
                        & path {fill: $gold; transition: .4s;}
                        &:hover path {fill: $white; transition: .4s;}
                    }
                    &.phone {display: inline-block; overflow: hidden; width: max-content; font-family: $charm; color: $gold; letter-spacing: 3px; border: 1px solid #856b39; transition: border-color .4s,color .4s,background-color .4s; white-space: nowrap; margin: 0 auto; padding: 10px 15px;
                        &::after {content: ""; z-index: -1; position: absolute; top: 0; right: 0; bottom: 0; left: 0; opacity: 0; display: block; background-color: #856b39; -webkit-transform: translateY(100%); transform: translateY(100%); transition: opacity 0s .4s,background-color .4s,-webkit-transform .4s cubic-bezier(.77,0,.175,1); transition: transform .4s cubic-bezier(.77,0,.175,1),opacity 0s .4s,background-color .4s; transition: transform .4s cubic-bezier(.77,0,.175,1),opacity 0s .4s,background-color .4s,-webkit-transform .4s cubic-bezier(.77,0,.175,1); width: 100%;}
                        &:hover {color: $black; -webkit-text-stroke-width: 1px;
                            &::after {opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); transition: opacity .4s,-webkit-transform 0s; transition: transform 0s,opacity .4s; transition: transform 0s,opacity .4s,-webkit-transform 0s;}
                        }
                    }
                }
            }
        }
    }
    body.mobile-menu-activate {
        & header {
            & nav {position: fixed; z-index: 1000; width: 100vw;}
            & .mobile-menu-panel.not-desktop {transform: translateX(0); transition: .3s; position: fixed; bottom: 0; z-index: 999;
                & .block-menu-links {margin-bottom: -100px;}
            }
        }
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {
    header {width: 100vw; height: 60px; background: $black;
        & nav {height: 60px; background: transparent;
            & #menuNav {
                & .block-menu-links {display: none;}
                & .block-logo {display: grid; grid-template-columns: max-content max-content; justify-content: space-between; align-items: center; width: 100%; background: $black; padding: 0;
                    & button {display: inline-flex; vertical-align: middle; position: relative; z-index: 0; border: 0; background-color: transparent; padding: 10px 0 0 0;
                        & #buttonMobileMenu {width: 40px; height: 30px; cursor: pointer; margin-right: 10px; position: relative; z-index: 1000000000;
                            & span {display: block; background: $white; height: 4px; border-top-right-radius: 4px; border-bottom-right-radius: 4px; transition: all .4s;;
                                &.ham_75 {margin-bottom: 6px; width: 75%; transform: translateY(.5px);}
                                &.ham_0 {transition: none;}
                                &.ham_50 {margin-top: 4px; width: 50%; transform: translateY(1.5px);}
                            }
                        }
                    }
                    & a {
                        & svg {width: auto; height: 40px; margin: 0;}
                    }
                }
                &.mobile-menu-activate {
                    & .block-logo {
                        & button {
                            & #buttonMobileMenu {
                                & span {width: 100%; position: absolute; transition: all .4s;
                                    &.ham_75 {top: 8px; transform: translateY(1.5px) translateX(0) rotate(45deg);}
                                    &.ham_0 {transition: none; visibility: hidden;}
                                    &.ham_50 {bottom: 16px; transform: translateY(.5px) translateX(0) rotate(-45deg);}
                                }
                            }
                        }
                    }
                }
            }
        }
        & .mobile-menu-panel.not-desktop {width: 100vw; height: calc(100vh - 55px); background: $black; margin: -5px 0 0; padding: 0 50px; transform: translateX(-100%); transition: .3s;
            & .block-menu-links {
                & a {width: max-content; font-family: $charm; color: $white; font-size: 7vw; line-height: 8vw; -webkit-text-stroke: 1px; margin: 0 auto;
                    &.active {color: $gold;}
                }
            }
            & .block-social-links {
                & a {display: flex;
                    & svg {width: 40px; height: 40px; margin: 0;
                        & path {fill: $gold; transition: .4s;}
                        &:hover path {fill: $white; transition: .4s;}
                    }
                    &.phone {display: inline-block; overflow: hidden; width: max-content; font-family: $charm; color: $gold; font-size: 4vw; letter-spacing: 3px; border: 1px solid #856b39; transition: border-color .4s,color .4s,background-color .4s; white-space: nowrap; margin: 0 auto; padding: 10px 15px;
                        &::after {content: ""; z-index: -1; position: absolute; top: 0; right: 0; bottom: 0; left: 0; opacity: 0; display: block; background-color: #856b39; -webkit-transform: translateY(100%); transform: translateY(100%); transition: opacity 0s .4s,background-color .4s,-webkit-transform .4s cubic-bezier(.77,0,.175,1); transition: transform .4s cubic-bezier(.77,0,.175,1),opacity 0s .4s,background-color .4s; transition: transform .4s cubic-bezier(.77,0,.175,1),opacity 0s .4s,background-color .4s,-webkit-transform .4s cubic-bezier(.77,0,.175,1); width: 100%;}
                        &:hover {color: $black; -webkit-text-stroke-width: 1px;
                            &::after {opacity: 1; -webkit-transform: translateY(0); transform: translateY(0); transition: opacity .4s,-webkit-transform 0s; transition: transform 0s,opacity .4s; transition: transform 0s,opacity .4s,-webkit-transform 0s;}
                        }
                    }
                }
            }
        }
    }
    body.mobile-menu-activate {
        & header {
            & nav {position: fixed; z-index: 1000; width: 100vw;}
            & .mobile-menu-panel.not-desktop {transform: translateX(0); transition: .3s; position: fixed; bottom: 0; z-index: 999;
                & .block-menu-links {margin-bottom: -100px;}
            }
        }
    }

}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
    header nav #menuNav .block-menu-links {margin: 150px 0 0 30px;
        & a {font-size: 27px; line-height: 27px;}
    }
}

@media screen and (min-width: 1900px) {
    #header-top {padding: 0 5%;}
}
