/** FONT - START **/
$oswald:    'Oswald', sans-serif;
$charm:    'Charm', cursive;
/** FONT - END **/

/** VARIABLES - START **/
$society-color: #9F5A47;
$black: #000;
$blue: #1eaaf1;
$blue_dark: #5f5871;
$dark-color: rgba(25,26,25);
$light-color: rgba(242,242,242);
$gray-light: #5f6368;
$orange: #fe7227;
$red_error: #cd3c63;
$white: #fff;
$box-shadow: rgba(0, 0, 0, .18) 5px 5px 4px;
$crud-label-color: #ff8e3ba8;
$crud-block-title-color: #267af8a8;

$gold:              #856b39;
$red-portugal:      #FA0F0C;
$green-portugal:    #136926;
$nude:              rgba(255, 244, 220);

$transition-hover: .3s ease-in-out;

$trans-03: .3s;
$trans-08: .8s;
$trans-10: 1s;
$trans-12: 1.2s;

.soc-color {color: $society-color;}
.color-red {color: red;}

/** VARIABLES - END **/

html {background: $black;}
* {font-family: $oswald; letter-spacing: .5px; line-height: normal;}
html {overflow: hidden; position: fixed; left: 0; right: 0; top: 0; bottom: 0;}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, /* ol, ul, li, */ fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    scroll-behavior: smooth;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display: block;}
body {line-height: 1;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after,
q:before, q:after {content: ''; content: none;}
table {border-collapse: collapse; border-spacing: 0;}

.c-pointer {cursor: pointer;}
.c-default {cursor: default;}
.c-zoom-in {cursor: zoom-in;}
.c-zoom-out {cursor: zoom-out;}
.p-rel {position: relative;}
.p-abs {position: absolute;}
.p-sti {position: sticky; position: -webkit-sticky;}
.p-fix {position: fixed;}
.o-hidden {overflow: hidden;}
.d-none {display: none;}
/** FONT STYLE **/
.fs-12 {font-size: 12px;}
.fs-16 {font-size: 16px;}
.fs-18 {font-size: 18px;}
.fs-20 {font-size: 20px;}
.fs-22 {font-size: 22px;}
.fs-24 {font-size: 24px;}
.fs-27 {font-size: 27px;}
.fs-32 {font-size: 32px;}
.fs-36 {font-size: 36px;}
.fs-44 {font-size: 44px;}
.fs-50 {font-size: 50px;}
.fs-51 {font-size: 51px;}
.fs-55 {font-size: 55px;}
.fs-65 {font-size: 65px;}
.fs-69 {font-size: 69px;}
.fs-77 {font-size: 77px;}
.fs-81 {font-size: 81px;}
.fs-85 {font-size: 85px;}
.fs-92 {font-size: 92px;}
.fw-100 {font-weight: 100;}
.fw-200 {font-weight: 200;}
.fw-300 {font-weight: 300;}
.fw-400 {font-weight: 400;}
.fw-500 {font-weight: 500;}
.fw-600 {font-weight: 600;}
.fw-700 {font-weight: 700;}
.fw-800 {font-weight: 800;}
.fw-900 {font-weight: 900;}
.db {display: block;}
.bold {font-weight: bold;}
.not-bold {font-weight: 400;}
.underline {text-decoration: underline;}
.italic {font-style: italic;}
.lowercase {text-transform: lowercase;}
.uppercase {text-transform: uppercase;}
.ta-center {text-align: center;}
.ta-left {text-align: left;}
.ta-right {text-align: right;}
.ta-justify {text-align: justify;}
/** DECORATION **/
.br-5 {border-radius: 5px;}
.br-10 {border-radius: 10px;}
.br-15 {border-radius: 15px;}
.br-20 {border-radius: 20px;}
.br-25 {border-radius: 25px;}
.br-50 {border-radius: 50%;}
/** FLEX **/
.df {display: flex;}
.fd-row {flex-direction: row;}
.fd-row-rev {flex-direction: row-reverse;}
.fd-col {flex-direction: column;}
.fd-col-rev {flex-direction: column-reverse;}
.fw-no {flex-wrap: nowrap;}
.fw-wrap {flex-wrap: wrap;}
.jcfs {justify-content: flex-start;}
.jcc {justify-content: center;}
.jcfe {justify-content: flex-end;}
.jcsa {justify-content: space-around;}
.jcsb {justify-content: space-between;}
.aib {align-items: baseline;}
.jic {justify-items: center;}
.aifs {align-items: flex-start;}
.ais {align-items: start;}
.aistr {align-items: stretch;}
.aic {align-items: center;}
.aife {align-items: flex-end;}
.acc {align-content: center;}
.acsb {align-content: space-between;}
.acsa {align-content: space-around;}
.jsfs {justify-self: flex-start;}
.jsc {justify-self: center;}
.jsfe {justify-self: flex-end;}
.asb {align-self: baseline;}
.asfs {align-self: flex-start;}
.asc {align-self: center;}
.asfe {align-self: flex-end;}
/** GAP **/
.gap-05 {gap: 5px;}
.gap-10 {gap: 10px;}
.gap-20 {gap: 20px;}
.gap-30 {gap: 30px;}
.gap-40 {gap: 40px;}
.gap-50 {gap: 50px;}
.gap-60 {gap: 60px;}
.gap-70 {gap: 70px;}
.gap-80 {gap: 80px;}
.gap-90 {gap: 90px;}
.gap-100 {gap: 100px;}
.gap-120 {gap: 120px;}
/** GRID **/
.dg {display: grid;}
.gtc-10-90 {grid-template-columns: 10% 90%;}
.gtc-15-85 {grid-template-columns: 15% 85%;}
.gtc-20-80 {grid-template-columns: 20% 80%;}
.gtc-25-75 {grid-template-columns: 25% 75%;}
.gtc-30-70 {grid-template-columns: 30% 70%;}
.gtc-35-65 {grid-template-columns: 35% 65%;}
.gtc-40-60 {grid-template-columns: 40% 60%;}
.gtc-40-1fr {grid-template-columns: 40% 1fr;}
.gtc-45-55 {grid-template-columns: 45% 55%;}
.gtc-50-50 {grid-template-columns: 50% 50%;}
.gtc-55-45 {grid-template-columns: 55% 45%;}
.gtc-60-40 {grid-template-columns: 60% 40%;}
.gtc-60-1fr {grid-template-columns: 60% 1fr;}
.gtc-65-35 {grid-template-columns: 65% 35%;}
.gtc-70-30 {grid-template-columns: 70% 30%;}
.gtc-75-25 {grid-template-columns: 75% 25%;}
.gtc-80-20 {grid-template-columns: 80% 20%;}
.gtc-85-15 {grid-template-columns: 85%15%;}
.gtc-90-10 {grid-template-columns: 90% 10%;}
.gtc-95-05 {grid-template-columns: 95% 5%;}
.gtc-1fr {grid-template-columns: 1fr;}
.gtc-2fr {grid-template-columns: repeat(2, 1fr);}
.gtc-3fr {grid-template-columns: repeat(3, 1fr);}
.gtc-3-30 {grid-template-columns: repeat(3, 30%);}
.gtc-4fr {grid-template-columns: repeat(4, 1fr);}
.gtc-5fr {grid-template-columns: repeat(5, 1fr);}
.gtc-max {grid-template-columns: max-content;}
.gtc-3-max {grid-template-columns: repeat(3, max-content);}
.gtc-4-max {grid-template-columns: repeat(4, max-content);}
.gtc-1-max {grid-template-columns: 1fr max-content;}
.gtc-max-1 {grid-template-columns: max-content 1fr;}
.gtc-1-max-1 {grid-template-columns: 1fr max-content 1fr;}
.gtc-max-1-max {grid-template-columns: max-content 1fr max-content;}
.gtc-max-max {grid-template-columns: max-content max-content;}
.gtc-auto {grid-template-columns: auto;}
/** HOVER **/
.b-rad-8 {border-radius: 8px;}

.not-desktop-grid {display: none;}
.not-desktop-flex {display: none;}

@media (max-width: 767.98px) {
    .not-mobile {display: none;}
    .not-desktop-grid {display: grid;}
    .not-desktop-flex {display: flex;}
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
}

@media screen and (min-width: 1900px) {
}
