#pages[data-route="home"] {background: white; padding-bottom: 50px;
    & .mySlides {background: black;
        & .banner {width: 100%; height: calc(100vh - 60px); border-bottom: 5px solid black;
            &.not-desktop {display: none!important;}
        }
        & .text {z-index: 6; bottom: 200px; left: 50px; font-family: $charm; color: white; font-size: 55px; -webkit-text-stroke: 1px; text-shadow: 2px 2px 3px black;}
        & .short-description { z-index: 5; bottom: 150px; left: 50px; background: black; color: white; font-size: 25px; padding: 0 10px 5px;}
        & .banner-link {z-index: 6; bottom: 150px; right: 50px; display: block; color: white; background-color: $red-portugal; border: .5px solid $red-portugal; border-radius: 8px; width: 130px; height: 40px; padding: 7px 20px 0; transition: all 0.5s ease; overflow: hidden; text-align: center;
            &:after {position: absolute; content: ""; width: 0; height: 100%; top: 0; right: 0; z-index: -1; background-color: black; transition: all 0.5s ease;}
            &:hover {color: white; border: .5px solid black;}
            &:hover:after {left: 0; width: 100%;}
        }
        &.fade {animation-name: fade; animation-duration: 1.5s;}
        @keyframes fade {from {opacity: .4;} to {opacity: 1;}}
        &::after {content: ""; position: absolute; left: 0; right: 0; bottom: 0; width: 100%; height: 360px; background-image: linear-gradient(180deg, transparent, #00000077); z-index: 5;}
    }
    & h1 {z-index: 6; width: max-content; background: black; border-radius: 15px 15px 0 0; font-family: $charm; color: $gold; -webkit-text-stroke: 1px; text-shadow: 2px 2px 3px black; margin: -102px auto 0; padding: 20px 40px 10px; width: 770px; height: 120px; margin: -120px auto 120px;
    }
    & h2 {font-family: $charm; margin: 60px auto 0; -webkit-text-stroke: 1px;}
    & #history {display: grid; grid-template-columns: 55% calc(45% - 20px); margin-top: 70px;
        & .content {padding: 30px 70px 30px 90px;
            & h2 {margin: 0 0 60px;}
            & p {line-height: 27px; letter-spacing: 1px;}
            & .know-more {margin-top: 50px;
                & a {z-index: 1; display: inline-block; color: black; background-color: white; border: .5px solid #212121; border-radius: 8px; width: max-content; height: max-content; padding: 10px 20px; transition: all 0.5s ease; overflow: hidden;
                    &:after {position: absolute; content: ""; width: 0; height: 100%; top: 0; right: 0; z-index: -1; background-color: black; transition: all 0.5s ease;}
                    &:hover {color: white;}
                    &:hover:after {left: 0; width: 100%;}
                }
            }
        }
        & img {width: 100%; height: auto; border: 20px solid white;}
    }
    & #presentationVideo {
        & video {width: calc(100% - 100px); border-radius: 50% 20% / 10% 40%; box-shadow: 8px 8px 5px 0 rgba(255, 0, 0, .36); margin: 50px;}
    }
    & #homeGateway {height: 577px; margin: 20px;
        & a {transition: 1.2s;
            & .image {background-size: cover; background-position: center center; height: 577px; width: 100%; transition: 1.2s;}
            & img {width: 100%; height: auto; transition: 1.2s;
            }
            & .open-link {z-index: 1; bottom: 0; height: 100%; width: 100%; padding-bottom: 30px; transition: .8s;
                & .title {bottom: 60px; font-family: $charm; color: white; text-shadow: 2px 2px black;}
            }
            &:hover {transition: 1.2s;
                & .image {transform: scale(1.1);}
                & .open-link {background: #00000085;}
            }
            &:first-of-type {
                & .open-link, & .image {border-radius: 25px 0 0 25px;}
            }
            &:last-of-type {
                & .open-link, & .image {border-radius: 0 25px 25px 0;}
            }
        }
    }
    & .last-articles {padding: 20px;
        & a.last-single {border: 1px solid #ddd; border-radius: 15px; transition: $transition-hover;
            & img {width: 100%; height: 210px; border-radius: 15px 15px 0 0;}
            & .informations {height: 150px; background: white; border: 1px solid white; border-radius: 0 0 15px 15px; padding: 10px 20px 20px;
                & h3.article-title {cursor: pointer; width: calc(100% - 20px); color: black; margin-top: 10px; transition: $transition-hover;}
                & .article-date {color: black;}
            }
            &:hover {cursor: pointer; box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, .2); transition: $transition-hover;
                & .article-title {cursor: pointer; transition: $transition-hover;}
            }
        }
    }
    & .block-button {margin-top: 50px;
        & a {z-index: 1; display: inline-block; color: black; background-color: white; border: .5px solid #212121; border-radius: 8px; width: max-content; height: max-content; padding: 10px 20px; transition: all 0.5s ease; overflow: hidden;
            &:after {position: absolute; content: ""; width: 0; height: 100%; top: 0; right: 0; z-index: -1; background-color: black; transition: all 0.5s ease;}
            &:hover {color: white;}
            &:hover:after {left: 0; width: 100%;}
        }
    }
}

@media (max-width: 767.98px) {
    #pages[data-route="home"] {position: relative;
        & .slideshow-container {height: calc(100vh - 60px); background-color: black; padding: 40px 0 0;
            & .mySlides {
                & .banner {width: 100vw; height: 100vw;}
                & .text {z-index: 7; bottom: -120px; left: 0; width: 100%; font-size: 8.5vw; text-align: center; padding: 0 30px;}
                & .short-description {bottom: -10px; left: 0; font-size: 5.5vw; text-align: center; padding: 0 10px 5px;}
                & .banner-link {position: absolute; bottom: 10vh; right: 30px; left: initial;}
                &::after {background-image: none;}
            }
        }
        & h1 {position: absolute; top: 0; display: flex; justify-content: center; width: 100vw; height: 50px; border-radius: 0; font-size: 6.5vw; line-height: 6.5vw; margin: 50px auto; padding: 15px 30px 0;}
        & h2 {font-size: 12vw; line-height: 12.5vw;}
        & #history {grid-template-columns: 1fr; margin-top: 30px;
            & img {order: 1;}
            & .content {order: 2; padding: 20px;
                & .know-more a {margin: 0 auto;}
            }
        }
        & #presentationVideo video {width: 100vw; border-radius: 0; box-shadow: none; margin: 0; padding: 50px 0 0; }
        & #homeGateway {grid-template-columns: 1fr; height: auto;
            & a {height: 100vw;
                & .image {height: 130vw;}
                &:first-of-type {
                    & .open-link, & .image {border-radius: 25px 25px 0 0;}
                }
                &:last-of-type {
                    & .open-link, & .image {border-radius: 0 0 25px 25px;}
                }
            }
        }
        & .last-articles {grid-template-columns: 1fr;}
        & .block-button {margin-top: 20px;}
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {
    #pages[data-route="home"] {padding: 60px 0 0;
        & .mySlides {
            & .banner {height: auto;}
            & .text {font-size: 4.5vw;}
        }
        & h1 {width: max-content; font-size: 5.5vw; height: 100px; margin: -100px auto 50px;}
        & #history {grid-template-columns: 1fr; margin-top: 0;
            & img {width: 80%; margin: 0 auto 30px;}
            & .content .know-more {justify-content: center;}
        }
        & #presentationVideo video {width: calc(100% - 70px); margin: 50px 35px;}
        & #homeGateway {height: 437px;
            & a {
                & .image {height: 437px;}
                & .open-link .title {font-size: 5.5vw;}
            }
        }
        & .last-articles {grid-template-columns: repeat(2, 1fr);
            & a.last-single {
                & img {height: 270px;}
                & .informations h3.article-title {font-size: 2.5vw;}
            }
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
    #pages[data-route="home"] {
        & .mySlides .text {font-size: 4vw;}
        & h1 {width: max-content; height: 110px; font-size: 4vw; margin: -110px auto 120px;}
        & #history {grid-template-columns: 45% calc(55% - 20px);
            & .content {padding: 30px 30px 30px 30px;
                & h2 {font-size: 44px; line-height: 44px; margin: 0 0 20px;}
                & p {font-size: 16px; line-height: 24px;}
                & .know-more {margin-top: 20px;}
            }
        }
        & .last-articles a.last-single .informations h3.article-title {font-size: 1.8vw; line-height: 2vw;}
    }
}

@media screen and (min-width: 1900px) {
    #pages[data-route="home"] {
        & h1 {width: max-content;}
        & #history {padding: 0 20% 50px;
            & .content {height: 90%; padding-top: 130px;
                & .know-more {height: max-content;}
            }
        }
        & #presentationVideo {padding: 0 15% 50px;}
        & #homeGateway {padding: 0 15% 50px; height: 750px;
            & a .image {height: 750px;}
        }
        & .last-articles {padding: 0 15% 50px;
            & a.last-single img {height: auto;}
        }
        & #blockSocial {margin: 120px 27% 0;}
    }
}
