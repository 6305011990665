#pages[data-route="restaurant"] {background: $nude; padding: 80px 120px 0 360px;
    & h1 {z-index: 2; top: 120px; left: 45%;
        & svg {width: 70px; margin: 10px 20px 0 0;
            & path {fill: $gold;}
        }
        & .two-lines {
            & span {font-family: $charm; color: $gold; line-height: 85px; -webkit-text-stroke: 3px;}
        }
    }
    & .block-first {
        & img {z-index: 1; width: 100%; border: 10px solid $white; box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 6px 6px 6px rgba(0, 0, 0, .23);}
        & .address-schedules {color: $black; line-height: 24px; -webkit-text-stroke: 1px; padding-bottom: 80px;
            & svg {width: 15px; margin-top: 5px;
                & path {fill: $gold;}
            }
            & .address, .schedules {gap: 20px; margin-bottom: 30px;}
            & .detail {
                & a {color: $gold;
                    &:hover {color: $red-portugal;}
                }
                & .line-day {grid-template-columns: 80px 1fr;
                    & .left {
                        & .day {width: 70px; color: $gold; margin-right: 5px;}
                    }
                    & .right {
                        & .closed {color: #b82827;}
                        & .schedule {
                            & .schedule-single {display: inline-block; width: 50px;}
                            & .schedule-separator {margin: 0 2px;}
                        }
                    }
                }
            }
        }
    }
    & .block-second {margin-top: -80px;
        & .slogan {z-index: 1; padding-bottom: 120px;
            & .container {width: 215px; color: $black; line-height: 27px; border-top: 5px solid $black; border-bottom: 5px solid $black; padding: 10px 20px;}
        }
        & img {z-index: 2; width: 110%; border: 10px solid #FFF; box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 6px 6px 6px rgba(0, 0, 0, .23);}
    }
    & .block-reassurance {height: 530px; margin: 50px 0 100px;
        & .left {
            & .background-gold {top: -400px; left: 70px; background: $gold; width: 250px; height: 550px;}
            & .left-picture {z-index: 1; top: 120px; width: 45%; height: auto; border: 10px solid #FFF; box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 6px 6px 6px rgba(0, 0, 0, .23);}
            & .right-picture {bottom: 30px; right: 0; width: 65%; height: auto; border: 10px solid #FFF; box-shadow: 0 10px 20px rgba(0, 0, 0, .19), 6px 6px 6px rgba(0, 0, 0, .23);}
        }
        & .right {padding: 120px 0 40px 40px;
            & h2 {color: $gold; margin-bottom: 20px;}
            & .block-dash {top: 135px; left: -130px; background-color: $gold; width: 150px; height: 5px; z-index: 1;}
            & .summary {
                & p {color: #808080; line-height: 27px; letter-spacing: 1px;
                    &:first-child {margin-bottom: 10px;}
                    &:last-child {margin-top: 10px; margin-bottom: 20px;}
                }
            }
        }
    }
    & .block-menu {margin-top: 50px; padding: 90px 0 0 0;
        & h2 {width: 200px;
            & svg {width: 40px; margin-right: 20px;
                & path {fill: $gold;}
            }
            & span {font-family: $charm; color: $gold; line-height: 50px; -webkit-text-stroke: 3px;}
        }
        & .menus {padding: 5vw 0;
            & .menu-nav {z-index: 3; top: 72px; width: 200px; height: fit-content;
                & button {width: 160px; color: $black; line-height: 21px; letter-spacing: 1px; letter-spacing: .1125vw; background: transparent; border: none; margin-bottom: 36px; padding: 0; transition: .4s;
                    & span {z-index: 1; background: $nude; padding-left: .9375vw;}
                    &:hover {cursor: pointer; color: $gold; transition: .4s;}
                    &.selected {color: $gold;
                        &::before {height: 0.1875vw; top: 0.67vw;}
                        &::after {height: 0.1875vw; top: 0.4375vw;}
                        &::before {background-color: $gold; bottom: 0; content: ""; left: 0; position: absolute; width: 100%; z-index: 0; animation-name: entry; animation-duration: 3s;}
                        @keyframes entry {
                            0%      {transform: translateX(-102%);}
                            75%     {transform: translateX(0%);}
                            100%    {transform: translateX(0%);}

                        }
                    }
                    &#mealCategoryBoissons {order: 5;}
                    &#mealCategoryDesserts {order: 4;}
                    &#mealCategoryEntrées {order: 1;}
                    &#mealCategoryPoissons {order: 2;}
                    &#mealCategoryViandes {order: 3;}
                }
                & .modal-preview {z-index: 9; top: 60px; left: 240px; width: calc(100vw - 240px); height: calc(100vh - 60px); background: $black;
                    & .modal-name {max-width: 700px; font-family: $oswald; letter-spacing: .5px; color: $gold; font-size: 2rem; line-height: 2.4rem;}
                    & img {height: 70vh; border: 5px solid $white; border-radius: 15px;}
                    & .close-button {
                        & svg {width: 20px;
                            & path {fill: $gold;}
                        }
                        & span {color: $gold;}
                        &:hover span {text-decoration: underline;}
                    }
                }
                & .wine-content {display: none; z-index: 1; position: fixed; top: 60px; right: 0; grid-template-columns: 1fr; gap: 20px; align-items: start; gap: 0; background: $nude; width: calc(100vw - 240px); height: calc(100vh - 58px); padding: 0; transition: 1.5s;
                    & .top-line-modal {margin: 15px 50px 0;
                        & .close-button {display: flex; align-items: center; width: max-content; cursor: pointer; color: $gold; margin-left: 20px;
                            & svg {width: 20px; height: 20px;
                                & path {fill: $gold}
                            }
                            & span {font-weight: 600; padding-left: 5px;
                            }
                            &:hover {
                                & span {text-decoration: underline;}
                            }
                        }
                        & .wine-type {font-family: $charm; color: $black; font-size: 27px; line-height: 26px; -webkit-text-stroke: 1px; border-radius: 15px; margin: -15px 0px -15px 0; padding: 12px 15px;
                            &.wine-type-red {background: #AC1E44; color: $white;}
                            &.wine-type-white {background: #FFEFC5;}
                            &.wine-type-pink {background: #FD98BB;}
                            &.wine-type-vinho-verde-red {background: #DC143C; color: $white;}
                            &.wine-type-vinho-verde-white {background: #eeffc8;}
                            &.wine-type-vinho-verde-pink {background: #FEE7F0;}
                            &.wine-type-porto-red {background: #A84154; color: $white;}
                            &.wine-type-porto-white {background: #FDF1B8;}
                        }
                    }
                    & .wine-detail {display: grid; grid-template-columns: 55% 1px 1fr; gap: 20px; background: $white; height: calc(100vh - 140px); border: 1px solid #ddd; border-radius: 15px; box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2); margin: 0px 15%; padding: 20px;
                        & .block-product {display: flex; flex-direction: column; justify-content: space-between;
                            & .introduction {
                                & .product-name {position: relative; display: flex; justify-self: flex-start; width: max-content;
                                    & .name {z-index: 1; font-family: $charm; font-size: 27px; line-height: 27px; -webkit-text-stroke: 1px;}
                                    & .border-bottom-block {position: absolute; bottom: 0; left: -5px; z-index: 0; background: #F6C66B; opacity: .36; width: 90%; height: 10px;}
                                }
                                & .product-description {font-size: 12px; margin-top: 20px; padding-left: 5px;}
                            }
                            & .bottom {display: grid; grid-template-columns: 1fr 30%; align-items: flex-end; gap: 20px;
                                & img {justify-self: flex-end; width: 85%; max-width: 80px; height: auto;}
                                & .caracteristics-block {
                                    & .line {margin-bottom: 15px;
                                        & .top {display: grid; grid-template-columns: 20px 1fr; gap: 5px;
                                            & svg {width: 20px; height: 20px;}
                                            & .title {font-weight: 500; line-height: 24px;}
                                        }
                                        & .caracteristic {font-size: 12px; padding-left: 5px;}
                                    }
                                }
                            }
                        }
                        & .separation-border {display: flex; align-items: center;
                            & span {background: #ddd; width: 1px; height: 80%;}
                        }
                        & .block-area {width: 100%; height: calc(100vh - 180px);
                            & img {width: 100%; height: auto; border-radius: 15px; box-shadow: 2px 2px 14px #a3a3a3, 2px 2px 12px $white;}
                            & .bottom {display: grid; height: calc(100vh - 380px); padding: 10px;
                                & .area-name {position: relative; display: flex; justify-self: flex-end; width: max-content;
                                    & .name {width: max-content; font-family: $charm; font-size: 27px; -webkit-text-stroke: 1px; line-height: 27px;}
                                    & .border-bottom-block {position: absolute; bottom: 0; right: -5px; z-index: 0; background: #fa0f0cc2; opacity: .36; width: 90%; height: 10px;}
                                }
                                & .area-description {overflow: scroll; font-size: 12px; text-align: justify; margin-top: 20px; padding-right: 10px;
                                    &::-webkit-scrollbar {width: 8px; height: 0;}
                                    &::-webkit-scrollbar-track {background: transparent;}
                                    &::-webkit-scrollbar-thumb {background: $red-portugal;}
                                }
                                &.alentejo {
                                    & .area-name {
                                        & .border-bottom-block {background: #C75969;}
                                    }
                                    & .area-description {
                                        &::-webkit-scrollbar-thumb {background: #C75969;}
                                    }
                                }
                                &.dao {
                                    & .area-name {
                                        & .border-bottom-block {background: #80aece;}
                                    }
                                    & .area-description {
                                        &::-webkit-scrollbar-thumb {background: #80aece;}
                                    }
                                }
                                &.douro {
                                    & .area-name {
                                        & .border-bottom-block {background: #C0D898;}
                                    }
                                    & .area-description {
                                        &::-webkit-scrollbar-thumb {background: #C0D898;}
                                    }
                                }
                                &.lisboa {
                                    & .area-name {
                                        & .border-bottom-block {background: #D5E281;}
                                    }
                                    & .area-description {
                                        &::-webkit-scrollbar-thumb {background: #D5E281;}
                                    }
                                }
                                &.minho {
                                    & .area-name {
                                        & .border-bottom-block {background: #6CDD6C;}
                                    }
                                    & .area-description {
                                        &::-webkit-scrollbar-thumb {background: #6CDD6C;}
                                    }
                                }
                                &.porto {
                                    & .area-name {
                                        & .border-bottom-block {background: #8C3043;}
                                    }
                                    & .area-description {
                                        &::-webkit-scrollbar-thumb {background: #8C3043;}
                                    }
                                }
                                &.setubal {
                                    & .area-name {
                                        & .border-bottom-block {background: #D77C6D;}
                                    }
                                    & .area-description {
                                        &::-webkit-scrollbar-thumb {background: #D77C6D;}
                                    }
                                }
                            }
                        }
                    }
                    &.active {display: grid; transition: 1.5s;}
                }
            }
            & .menu-detail {
                & .single-category {opacity: 1; transition: .6s; margin-top: -70px; padding-top: 70px;
                    & .dish-single {
                        & .top-line {z-index: 0; position: relative; grid-template-columns: calc(100% - 160px) 140px;
                            & .description-block {width: 100%;
                                & .block-name-picture {
                                    & .name {font-family: $oswald; letter-spacing: .5px; color: $gold; font-size: 1.28571429rem; line-height: 1.68571429rem; width: 520px;}
                                    & svg {width: 20px;
                                        & path {fill: $gold;}
                                        &:hover path {fill: $black;}
                                    }
                                }
                                & img.drink {bottom: 10px; width: auto; max-height: 90px;
                                    &.wine {max-height: 110px;}
                                }
                                & .name {font-family: $oswald; letter-spacing: .5px; color: $gold; font-size: 1.28571429rem; line-height: 1.68571429rem; width: 520px;
                                    &.drink {padding-left: 40px;}
                                }
                                & .bottom-line {width: 95%; color: $black; font-size: 18px; line-height: 24px; letter-spacing: 1px; margin: 10px 0 10px;
                                    &.drink {padding-left: 40px;
                                        &.banner-height {min-height: 50px;}
                                    }
                                }
                                & .block-images {
                                    & .single-image {
                                        & svg {width: 20px;
                                            & path {fill: #856b39;}
                                        }
                                        & .dish-name {font-family: $oswald; letter-spacing: .5px; color: $gold; font-size: 1.28571429rem; line-height: 1.68571429rem;}
                                        &:hover {
                                            & svg path {fill: $black;}
                                            & .dish-name {color: $black;}
                                        }
                                    }
                                }
                            }
                            & .prices-block {grid-template-columns: 60px 60px;
                                & .price {font-family: $oswald; letter-spacing: .5px; color: $gold; line-height: 1.28571429rem; -webkit-text-stroke: 1.1px; letter-spacing: .5px;}
                                & .button-preview-line {
                                    & button {width: calc(200% + 20px); background: $black; border: 0.5px solid $gold; border-radius: 5px; color: $white; padding: 5px;
                                        &:hover {background: $gold; color: $black;}
                                    }
                                }
                            }
                        }
                        & .border-bottom {background: $gold; width: 27%; height: 1px; margin: 20px 0 30px 0;}
                        &.title-supplement {margin-bottom: 50px;}
                    }
                    &.selected-category {display: block; animation-name: entryCategory; animation-duration: 2s;
                        & .drink-type {margin-left: 90px;
                            & svg {width: 30px; margin: 0 5px;
                                & path {fill: $gold;}
                            }
                            & .title {color: $gold; font-size: 34px; margin: 20px;}
                        }
                    }
                    & .wine-cellar-link-block {z-index: 2; top: 60px; background: $nude; height: 72px; margin-top: -12px; padding-top: 12px;
                        & .wine-cellar-link {width: max-content; background: $gold; border-radius: 15px; margin: 0 auto 50px; margin-left: 120px; padding: 5px 10px;
                            & div, a {color: $black;}
                            & a {width: max-content; background: $black; border: 1px solid $black; border-radius: 15px; color: $white; line-height: 26px; padding: 5px 10px; margin: 0 auto;
                                &:hover {background: $white; color: $black;}
                            }
                        }
                    }
                    @keyframes entryCategory {
                        0%      {opacity: 0;}
                        100%    {opacity: 1;}

                    }
                }
                & .block-french-meat {width: max-content; background: $gold; border-radius: 15px; padding: 0 20px;
                    & img {width: 40px; height: 40px;}
                    & span {text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
                        &.blue {color: #000091;}
                        &.white {color: $white;}
                        &.red {color: #e1000f;}
                    }
                }
            }
        }
    }
    & #blockSocial {margin: 0;}
}

@media (max-width: 767.98px) {
    #pages[data-route="restaurant"] {padding: 80px 5%;
        & h1 {top: 90px; left: initial; display: grid; grid-template-columns: max-content 1fr;
            & svg {width: 40px;}
            & .two-lines span {font-size: 11.5vw; line-height: 11.5vw;}
        }
        & .block-first {grid-template-columns: 1fr; padding-top: 130px; gap: 0px;
            & img {order: 2; width: 80%;}
            & .address-schedules {order: 1; padding-bottom: 0;}
        }
        & .block-second {grid-template-columns: 1fr;
            & .slogan {order: 2;}
            & img {order: 1; width: 90%; margin: 30px 10%;}
        }
        & .block-reassurance {grid-template-columns: 1fr; height: fit-content; margin: 50px 0 30px;
            & .left {
                & .background-gold {top: -470px; left: 20px; width: 280px; height: 650px;}
                & .left-picture {top: 130px; width: 80%;}
                & .right-picture {top: -140px; bottom: initial; width: 80%;}
            }
            & .right {padding: 550px 0 0 0;
                & .block-dash {display: none;}
            }
        }
        & .block-menu {margin-top: -30px; padding: 80px 0;
            & .menus {grid-template-columns: 1fr; gap: 50px;
                & .menu-nav {top: 60px; display: flex; flex-direction: row; justify-content: center; flex-wrap: wrap; gap: 10px; width: calc(100vw - 10%); background: $nude; /* margin-left: -5%; */ padding: 20px 0;
                    & button {display: flex; justify-content: center; align-items: center; width: max-content; background: $black; border-radius: 10px; margin: 0; padding: 5px 10px; transition: 0s;
                        & span {background: transparent; color: $white;}
                        &.selected {background: $gold; transition: 0s;
                            &::before {display: none;}
                            & span {color: $black;}
                        }
                    }
                    & .modal-preview {z-index: 999; position: fixed; left: 0; bottom: 0; align-content: center; gap: 20px; width: 100vw; height: calc(100vh - 55px); padding-bottom: 70px;
                        & .modal-name {max-width: 90%;}
                        & img {width: auto; max-width: 90vw; height: auto; max-height: 55vh;}
                        & .close-button {background: $black; padding: 10px 15px; border-radius: 10px;}
                    }
                    & .wine-content {z-index: 1; right: 0; width: 100vw; align-content: stretch;
                        & .top-line-modal {margin: 15px 10px 0;}
                        & .wine-detail {grid-template-columns: 1fr; overflow: scroll; border-radius: 0; height: calc(100vh - 200px); margin: -60px 20px 0;
                            &::-webkit-scrollbar {width: 13px; height: 0;}
                            &::-webkit-scrollbar-track {background: transparent;}
                            &::-webkit-scrollbar-thumb {background: transparent; border: solid 3px transparent; box-shadow: inset 0 0 5px 5px red;}
                            & .block-product {order: 2;
                                & .bottom {grid-template-columns: 1fr 65px; gap: 10px;}
                            }
                            & .separation-border {display: none;}
                            & .block-area {order: 1;}
                        }
                    }
                }
                & .menu-detail {width: calc(100vw - 10%);
                    & .single-category {width: calc(100vw - 10%); margin-top: 0; padding-top: 0;
                        & .dish-single {display: flex; flex-direction: column; width: calc(100vw - 10%);
                            & .top-line {grid-template-columns: 1fr;
                                & .description-block {width: calc(100vw - 10%);
                                    & .block-name-picture {display: flex;
                                        & .name {inline-size: calc(100vw - 10%);}
                                    }
                                    & .bottom-line {width: calc(100vw - 10%);}
                                    & .name {inline-size: calc(100vw - 10%);}
                                }
                            }
                            & .prices-block {justify-self: flex-end; width: max-content;}
                            &.not-banner .top-line .description-block {
                                & .name.drink {padding-left: 0;}
                                & .bottom-line.drink {padding-left: 0;}
                            }
                        }
                        & .block-french-meat {margin-bottom: 50px;}
                        & .wine-cellar-link-block {display: none;}
                        &.selected-category {
                            & .dish-single:first-of-type {margin-top: -214px; padding-top: 172px;}
                            & .drink-type {width: calc(100vw - 10%); line-height: 36px; text-align: center; margin-left: 0;
                                & .title {margin: 20px 0;}
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {
    #pages[data-route="restaurant"] {padding: 80px 40px 0 40px;
        & .block-second img {width: 100%;}
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
    #pages[data-route="restaurant"] {padding: 80px 60px 0 300px;
        & h1 {left: 50%;
            & .two-lines span {font-size: 5vw; line-height: 6vw;}
        }
        & .block-second {grid-template-columns: 36% 1fr;
            & .slogan {align-items: flex-end;
                & .container {width: max-content; margin-right: 15px;}
            }
            & img {width: 100%;}
        }
        & .block-reassurance {
            & .left {
                & .background-gold {height: 750px;}
                & .right-picture {top: 70px; bottom: initial; left: 150px;}
            }
            & .right {padding: 100px 0 40px 10px;
                & .block-dash {left: -150px;}
                & .summary {
                    & p {font-size: 1.3vw; line-height: 1.8vw;}
                }
            }
        }
        & .block-menu {
            & .menus {
                & .menu-nav {width: 120px;
                    & .wine-content {z-index: 2;
                        & .wine-detail {margin: 0px 5%;
                            & .block-area .bottom {height: calc(100vh - 400px);}
                        }
                    }
                }
                & .menu-detail .single-category {
                    & .dish-single .top-line {
                        & .description-block {width: 80%;
                            & .block-name-picture {grid-template-columns: max-content 1fr;
                                & .name {width: 480px; font-size: 1.6vw;}
                            }
                            & .bottom-line {width: 100%; font-size: 1.2vw; line-height: 1.5vw;}
                        }
                        & .prices-block .price {font-size: 1.3vw; line-height: 1.5vw;}
                    }
                    & .wine-cellar-link-block .wine-cellar-link {margin-left: 50px;}
                }
            }
        }
    }
}

@media screen and (min-width: 1900px) {
    #pages[data-route="restaurant"] {padding: 60px 20% 0 calc(20% + 240px);
        & h1 {top: 180px;
            & .two-lines span {font-size: 115px; line-height: 115px;}
        }
        & .block-first .address-schedules {font-size: 24px; line-height: 30px;
            & .detail .line-day {grid-template-columns: 100px 1fr;
                & .right .schedule .schedule-single {width: 60px;}
            }
        }
        & .block-second .slogan {padding-bottom: 180px;
            & .container {width: 295px; font-size: 27px; line-height: 35px;}
        }
        & .block-reassurance {height: 680px; margin: 200px 0 100px;
            & .left {
                & .background-gold {top: -710px; width: 370px; height: 840px;}
                & .left-picture {top: 80px;}
            }
            & .right {
                & h2 {font-size: 36px;}
                & .block-dash {top: 145px;}
                & .summary p {font-size: 20px;}
            }
        }
        & .block-menu {
            & h2 span {font-size: 60px; line-height: 60px;}
            & .menus {
                & .menu-nav {
                    & button {font-size: 20px; line-height: 24px;
                        &.selected::before {top: 0.47vw;}
                    }
                    & .wine-content {z-index: 1; right: 0; width: calc(100vw - 240px); height: calc(100vh - 58px); padding: 10vh 15%;
                        & .top-line-modal {margin: 15px 15% 0;}
                        & .wine-detail {height: 70vh;
                            & .block-area {height: calc(70vh - 180px);
                                & .bottom {height: calc(73vh - 380px);}
                            }
                        }
                    }
                }
                & .menu-detail .single-category {
                    & .wine-cellar-link-block .wine-cellar-link {width: 600px; margin-left: calc(50% - 300px);}
                    &.selected-category .drink-type {margin-left: 0;}
                    & .dish-single .top-line {grid-template-columns: calc(100% - 220px) 200px;
                        & .prices-block {grid-template-columns: 90px 90px;}
                        & .name, .price {font-size: 24px;}
                    }
                }
            }
        }
        & #blockSocial {margin: 120px 10% 0;}
    }
}
