@import "~semantic-ui-css/semantic.min.css";
@import "./base/base";
@import "./layout/global";
@import "./layout/header";
@import "./layout/footer";
@import './pages/page_home';
@import './pages/page_static';
@import './pages/page_about_us';
@import './pages/page_contact';
@import './pages/page_restaurant';
@import './pages/page_grocery';
@import './pages/page_wine_cellar';
@import './pages/page_actuality';
// @import "./pages/page_error";
