#pages[data-route="contact"] {background: $nude;
    & .banner {position: relative; height: 530px; justify-content: flex-start;
        & #map {z-index: 0; width: 100vw; min-height: 530px; max-height: 600px;}
        & h1 {position: absolute; bottom: -60px; width: 740px; background: $nude; border-radius: 0 20px 0 0; font-family: $charm; color: $black; text-shadow: 2px 3px 3px #00000055; padding-top: 20px!important; padding: 20px 0 20px 40px;}
    }
    & .block-address {display: grid; grid-template-columns: 1fr 1fr; gap: 100px; margin: 60px 0; padding: 0 100px;
        & .left-column {
            & a {width: max-content; display: flex; align-items: center; color: $gold; font-size: 27px; font-weight: 700; margin: 10px;
                & svg {width: 40px; height: 40px; margin-right: 10px;
                    & path {fill: $gold;}
                }
                & span {cursor: pointer;}
                &:hover {color: $black; text-decoration: underline;
                    & svg path {fill: $black;}
                }
            }
        }
        & .right-column {margin-top: -10px;
            & .top {font-size: 36px; font-weight: 800; line-height: 65px;}
            & .bottom {font-size: 44px; font-weight: 700;}
        }
    }
    & .block-form {padding: 50px 25% 80px;
        & .content-form {position: relative;
            & .contact-form-top {display: grid; grid-template-columns: 1fr; gap: 20px; margin-bottom: 20px;
                & .errors-block {position: absolute; left: 0; top: -50px; color: red; font-weight: 500; padding: 10px 20px; border-radius: 8px;
                    & ul { list-style: none; margin: 0; padding: 0;}
                }
                & .line {position: relative; display: grid; grid-template-columns: 1fr 1fr; gap: 20px;}
            }
            & input, select, textarea {width: 100%; height: 50px; background: $white; border: 1px solid #818181!important; box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2); border-radius: 8px; padding: 5px 5px 5px 10px;
                &:focus-visible {border: none; outline: 1px solid $red-portugal;}
            }
            & textarea {padding: 10px;}
            & input::placeholder, select {color: #818181;}
            & select {-webkit-appearance: none;}
            & textarea {height: 150px; resize: vertical;}
            & .agreed-terms {display: grid; grid-template-columns: max-content 1fr; gap: 10px; align-items: center; margin: 10px;
                & input {width: 15px; height: 15px;}
                & label {color: #818181; font-size: 14px; line-height: 20px; font-style: italic;}
            }
            .privacy-link-line {color: #818181;font-size: 14px; line-height: 14px; font-style: italic; margin: 10px 0 25px 36px;
                & a {color: $red-portugal;
                    &:hover {text-decoration: underline;}
                }
            }
            .recaptcha-block {display: flex; flex-direction: column; align-items: center; margin-top: 20px;
                & .errors-block {color: red; font-weight: 500; padding: 10px 20px; border-radius: 8px;
                    & ul { list-style: none; margin: 0; padding: 0;}
                }
            }
            & .block-button {display: flex; justify-content: center;
                & button {cursor: pointer; position: relative; z-index: 1; display: inline-block; font-size: 18px; font-weight: 600; background-color: $white; border: .5px solid $black; border-radius: 8px; color: $black; width: max-content; padding: 10px 20px; transition: all 0.5s ease; overflow: hidden;
                    &:after {position: absolute; content: ""; width: 0; height: 100%; top: 0; right: 0; z-index: -1; background-color: $black; border-radius: 0px; transition: all 0.5s ease;}
                    &:hover {color: $white;}
                    &:hover:after {left: 0; width: 100%;}
                }
            }
        }
    }
    & h2 {font-family: $charm; color: $black; font-size: 5vw; font-weight: 700; text-shadow: 2px 3px 3px #00000055;}
    & #accessPlan {margin-top: -120px; padding-top: 100px;
        & h2 {margin-bottom: 20px;}
    }
    & img.access-plan {width: 60%; box-shadow: 12px 12px 24px #a3a3a3, -12px -12px 24px rgb(255, 244, 220); border-radius: 8px; margin: 0px 20% 80px;
        &.top {top: 15px; right: -80px; width: 70px;}
    }
}

@media (max-width: 767.98px) {
    #pages[data-route="contact"] {
        & .banner {height: auto;
            & #map {min-height: 50vh;}
            & h1 {position: relative; bottom: inherit; width: 100vw; text-align: center; font-size: 12vw; padding: 20px;}
        }
        & .block-address {grid-template-columns: 1fr; justify-items: start; gap: 20px; margin: 0 5% 50px; padding: 0;
            & .left-column {
                & a {margin: 10px 0;
                    & span {font-size: 7vw;}
                }
            }
            & .right-column {width: calc(100vw - 10%);
                & .top {font-size: 7vw;}
                & .bottom {font-size: 8.5vw;}
                & .access-plan-link {position: absolute; top: -10px; right: calc(70px + 5%);}
            }
        }
        & .block-form {padding: 20px;
            & .content-form .contact-form-top .line {grid-template-columns: 1fr;}
        }
        & #accessPlan {margin-top: -80px;
            & h2 {font-size: 12vw;}
        }
        & img.access-plan {width: 80%; margin: 0px 10% 80px;}
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {
    #pages[data-route="contact"] {
        & .banner h1 {position: relative; bottom: initial; width: 100vw; text-align: center; font-size: 8vw; padding: 20px;}
        & .block-address {grid-template-columns: 1fr; gap: 30px; margin: 140px 0 0;
            & .left-column {justify-self: center;
                & a {margin: 10px auto;}
            }
        }
        & .block-form {padding: 50px 40px 80px;}
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
    #pages[data-route="contact"] {
        & .banner h1 {width: 40vw;}
        & .block-address .right-column {
            & .top {font-size: 2vw;}
            & .bottom {font-size: 2.5vw;}
        }
    }
}

@media screen and (min-width: 1900px) {
    #pages[data-route="contact"] {padding: 0 15% 0 calc(15% + 240px);
        & button, input, optgroup, select, textarea {font-size: 18px;}
        & .banner {height: 1000px;
            & #map {position: relative; width: 60vw; min-height: 1000px; box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 6px 6px 6px rgba(0, 0, 0, 0.23); border-radius: 60vw; margin: 0 auto;}
            & h1 {position: relative; bottom: 140px; width: 40vw; border-radius: 20px; font-size: 4vw; text-align: center; margin: 0 auto;}
        }
        & .block-address {margin: 90px 0 60px; padding: 0 20%;}
        & img.access-plan {width: 50%; margin: 0px 25% 80px;
            &.top {top: 10px; right: -120px; margin: 0px 25% 80px;}
        }
        & .block-form {min-height: calc(70vh - 160px);
            & .content-form {
                & textarea {height: 300px;}
                & .agreed-terms label {font-size: 16px; line-height: 18px;}
                & .privacy-link-line {font-size: 16px; line-height: 18px; margin: 10px 0 25px 35px;}
            }
        }
    }
}
