#pages[data-route="wine_cellar"] {display: grid; grid-template-columns: max-content 1fr; background: $nude;
    & h1 {position: absolute; top: 75px; left: 240px; width: calc(100% - 240px); font-family: $charm; font-size: 65px; line-height: 65px; text-align: center; -webkit-text-stroke: 1px;}
    & img {width: auto; height: calc(100vh - 60px);
        &.portugal-map {display: none;
            &.activated {display: block;}
        }
    }
    & .detail {position: relative; overflow: hidden;
        & .search-box {width: 100%; display: flex; align-items: center; flex-wrap: wrap; gap: 10px; margin: 0 auto; padding-top: 130px;
            & button {display: inline-block; font-size: 18px; text-decoration: none; background-color: $white; border: none; border-radius: 15px; box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2); padding: 10px 0;
                &:hover, &.is-checked {cursor: pointer; color: $white; background: $gold; border-bottom: none;}
                &.color {width: 98px;}
                &.size {width: 145px;}
            }
            & .top-search {
                & .button-group {display: grid; grid-template-columns: repeat(6, 98px); gap: 5px; padding-left: 155px;}
            }
            & .left-search {
                & .button-group {display: grid; grid-template-columns: 150px; gap: 5px;}
            }
        }
        & .list {position: absolute; top: 181px; left: 155px; width: 635px;
            & .block-wines {overflow: scroll; height: calc(100vh - 260px)!important; display: grid; grid-template-columns: 305px 305px; background: $nude;
                & .wine {display: grid; grid-template-columns: 1fr; justify-items: center; background: $white; border: 1px solid transparent; border-radius: 15px; box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2); width: 304px; height: 336px; margin: 0 0 5px 0; padding: 10px;
                    & img {width: auto; height: 240px;}
                    & .wine-name {font-family: $charm; font-size: 24px; line-height: 27px; padding: 10px; height: 90px; display: flex; justify-content: center; align-items: flex-start; text-align: center;}
                    &:hover {border: 1px solid $gold;}
                    &:hover {cursor: zoom-in;}
                }
                &::-webkit-scrollbar {width: 8px; height: 0;}
                &::-webkit-scrollbar-track {background: transparent;}
                &::-webkit-scrollbar-thumb {background: $gold; border-radius: 0;}
                & .wine-preview {display: none; position: fixed; z-index: 999; top: 60px; left: 240px; width: calc(100% - 240px); height: calc(100vh - 90px);
                    & .content-box {position: fixed; right: 0; width: calc(100% - 240px); height: calc(100vh - 60px); display: grid; grid-template-columns: max-content 1fr; align-items: start; background: $nude;
                        & .wine-content {display: grid; grid-template-columns: 1fr; padding: 20px; gap: 20px; align-items: start;
                            & .top-line-modal {
                                & .close-button {display: flex; align-items: center; width: max-content; cursor: pointer; margin-left: 20px;
                                    & svg {width: 20px; height: 20px;
                                        & path {fill: $gold;}
                                    }
                                    & span {color: $gold; font-weight: 600; padding-left: 5px;}
                                    &:hover {
                                        & span {text-decoration: underline;}
                                    }
                                }
                                & .wine-type {font-family: $charm; color: $black; font-size: 27px; line-height: 26px; -webkit-text-stroke: 1px; border-radius: 15px; margin: -15px 0px -15px 0; padding: 12px 15px;
                                    &.wine-type-red {background: #AC1E44; color: $white;}
                                    &.wine-type-white {background: #FFEFC5;}
                                    &.wine-type-pink {background: #FD98BB;}
                                    &.wine-type-vinho-verde-red {background: #DC143C; color: $white;}
                                    &.wine-type-vinho-verde-white {background: #eeffc8;}
                                    &.wine-type-vinho-verde-pink {background: #FEE7F0;}
                                    &.wine-type-porto-red {background: #A84154; color: $white;}
                                    &.wine-type-porto-white {background: #FDF1B8;}
                                }
                            }
                            & .wine-detail {display: grid; grid-template-columns: 55% 1px 1fr; gap: 20px;background: $white; height: calc(100vh - 140px); border: 1px solid #ddd; border-radius: 15px; box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.2); padding: 20px;
                                & .block-product {display: flex; flex-direction: column; justify-content: space-between;
                                    & .introduction {
                                        & .product-name {position: relative; display: flex; justify-self: flex-start; width: max-content;
                                            & .name {z-index: 1; font-family: $charm; font-size: 27px; line-height: 27px; -webkit-text-stroke: 1px;}
                                            & .border-bottom-block {position: absolute; bottom: 0; left: -5px; z-index: 0; background: #F6C66B; opacity: .36; width: 90%; height: 10px;}
                                        }
                                        & .product-description {font-size: 12px; margin-top: 20px; padding-left: 5px;}
                                    }
                                    & .bottom {display: grid; grid-template-columns: 1fr 30%; align-items: flex-end; gap: 20px;
                                        & img {justify-self: flex-end; width: 85%; max-width: 80px; height: auto;}
                                        & .caracteristics-block {
                                            & .line {margin-bottom: 15px;
                                                & .top {display: grid; grid-template-columns: 20px 1fr; gap: 5px;
                                                    & svg {width: 20px; height: 20px;}
                                                    & .title {font-weight: 500; line-height: 24px;}
                                                }
                                                & .caracteristic {font-size: 12px; padding-left: 5px;}
                                            }
                                        }
                                    }
                                }
                                & .separation-border {display: flex; align-items: center;
                                    & span {background: #ddd; width: 1px; height: 80%;}
                                }
                                & .block-area {width: 100%; height: calc(100vh - 180px);
                                    & img {width: 100%; height: auto; border-radius: 15px; box-shadow: 2px 2px 14px #a3a3a3, 2px 2px 12px $white;}
                                    & .bottom {display: grid; height: calc(100vh - 380px); padding: 10px;
                                        & .area-name {position: relative; display: flex; justify-self: flex-end; width: max-content;
                                            & .name {width: max-content; font-family: $charm; font-size: 27px; -webkit-text-stroke: 1px; line-height: 27px;}
                                            & .border-bottom-block {position: absolute; bottom: 0; right: -5px; z-index: 0; background: #fa0f0cc2; opacity: .36; width: 90%; height: 10px;}
                                        }
                                        & .area-description {overflow: scroll; font-size: 12px; text-align: justify; margin-top: 20px; padding-right: 10px;
                                            &::-webkit-scrollbar {width: 8px; height: 0;}
                                            &::-webkit-scrollbar-track {background: transparent;}
                                            &::-webkit-scrollbar-thumb {background: $red-portugal;}
                                        }
                                        &.alentejo {
                                            & .area-name {
                                                & .border-bottom-block {background: #C75969;}
                                            }
                                            & .area-description {
                                                &::-webkit-scrollbar-thumb {background: #C75969;}
                                            }
                                        }
                                        &.dao {
                                            & .area-name {
                                                & .border-bottom-block {background: #80aece;}
                                            }
                                            & .area-description {
                                                &::-webkit-scrollbar-thumb {background: #80aece;}
                                            }
                                        }
                                        &.douro {
                                            & .area-name {
                                                & .border-bottom-block {background: #C0D898;}
                                            }
                                            & .area-description {
                                                &::-webkit-scrollbar-thumb {background: #C0D898;}
                                            }
                                        }
                                        &.lisboa {
                                            & .area-name {
                                                & .border-bottom-block {background: #D5E281;}
                                            }
                                            & .area-description {
                                                &::-webkit-scrollbar-thumb {background: #D5E281;}
                                            }
                                        }
                                        &.minho {
                                            & .area-name {
                                                & .border-bottom-block {background: #6CDD6C;}
                                            }
                                            & .area-description {
                                                &::-webkit-scrollbar-thumb {background: #6CDD6C;}
                                            }
                                        }
                                        &.porto {
                                            & .area-name {
                                                & .border-bottom-block {background: #8C3043;}
                                            }
                                            & .area-description {
                                                &::-webkit-scrollbar-thumb {background: #8C3043;}
                                            }
                                        }
                                        &.setubal {
                                            & .area-name {
                                                & .border-bottom-block {background: #D77C6D;}
                                            }
                                            & .area-description {
                                                &::-webkit-scrollbar-thumb {background: #D77C6D;}
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 767.98px) {
    #pages[data-route="wine_cellar"] {height: 100vh;
        & h1 {left: initial; width: 100%; font-size: 10vw; line-height: 10vw; text-align: right; padding-right: 10vw;}
        & img {height: 200px;}
        & .detail {position: absolute; top: 260px; border-top: 10px solid $gold; width: 100vw; height: 100vh;
            & .search-box {position: fixed; top: 70px; left: 30vw; width: 70vw; padding-top: 90px;
                & .top-search {
                    & .button-group {grid-template-columns: repeat(6, max-content); padding-left: 0;}
                }
                & button {font-size: 2.3vw; padding: 5px;
                    &.color {width: max-content;}
                    &.size {width: 55px;}
                }
                & .left-search {
                    & .button-group {display: flex; flex-wrap: wrap;}
                }
            }
            & .list {position: fixed; top: 280px; left: 20px; width: calc(100% - 40px);
                & .block-wines {height: calc(100vh - 340px) !important;
                    & .wine {width: calc(50vw - 30px); height: calc(50vw - 20px);
                        & img {height: 80px;}
                        & .wine-name {font-size: 4.5vw; line-height: 5vw; height: 60px;}
                    }
                    & .wine-preview {left: 0; width: 100%;
                        & .content-box {grid-template-columns: 1fr; width: 100%;
                            & .wine-content {position: fixed; top: 270px; gap: 0; height: calc(100vh - 360px); padding: 0 20px;
                                & .top-line-modal {
                                    & .close-button {z-index: 1; position: fixed; top: 70px; right: 20px;}
                                    & .wine-type {display: none; position: fixed; top: 80px; left: 30vw; font-size: 3.5vw; line-height: 3.5vw; -webkit-text-stroke: 0; padding: 10px;}
                                }
                                & .wine-detail {overflow: scroll; grid-template-columns: 1fr; height: calc(100vh - 360px); border-radius: 0;
                                    &::-webkit-scrollbar {width: 13px; height: 0;}
                                    &::-webkit-scrollbar-track {background: transparent;}
                                    &::-webkit-scrollbar-thumb {background: transparent; border: solid 3px transparent; box-shadow: inset 0 0 5px 5px red;}
                                    & .block-product {gap: 10px; height: calc(100vh - 400px);
                                        & .bottom {grid-template-columns: 1fr 20%;
                                            & img {margin-bottom: 10px;}
                                        }
                                    }
                                    & .separation-border {display: none;}
                                    & .block-area {position: fixed; top: 100px; left: 30vw; width: auto; height: auto;
                                        & img {display: none;}
                                        & .bottom {width: 65vw; height: 200px; margin-top: -40px;
                                            & .area-name {justify-self: flex-start;}
                                            & .area-description {margin-top: 10px;}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {

}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
    #pages[data-route="wine_cellar"] {
        & h1 {left: 50vw; width: calc(100% - 50vw);}
        & .detail {height: calc(100vh - 72px);
            & .search-box {padding-top: 110px;
                & .top-search .button-group {grid-template-columns: repeat(6, 80px); padding-left: 0;
                    & button {width: 80px; font-size: 1.2vw;}
                }
                & .left-search .button-group {display: flex; flex-wrap: wrap;}
                & button {width: 80px; font-size: 1.2vw;
                    &.size {width: 80px;}
                }
            }
            & .list {top: 251px; left: 0;
                & .block-wines {grid-template-columns: 175px 175px 175px; height: calc(100vh - 320px) !important;
                    & .wine {width: 174px; height: 216px;
                        & img {height: 140px;}
                        & .wine-name {font-size: 1.5vw; line-height: 1.7vw;}
                    }
                    & .wine-preview .content-box .wine-content .wine-detail {
                        & .block-product {
                            & .introduction .product-description {font-size: .8vw; line-height: 1vw;}
                            & .bottom {grid-template-columns: 1fr 20%;
                                & .caracteristics-block .line {
                                    & .top .title {font-size: 1.2vw; line-height: 1.8vw;}
                                    & .caracteristic {font-size: .8vw; line-height: 1vw;}
                                    & img {width: 100%;}
                                }
                            }
                        }
                        & .block-area .bottom {height: calc(100vh - 300px);
                            & .area-description {font-size: .8vw; line-height: 1vw;}
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1900px) {
    #pages[data-route="wine_cellar"] {
        & .detail {
            & .search-box {width: 50%; margin: initial; padding: 240px 0 0 0;}
            & .list {top: 291px; left: 155px; width: 1270px;
                & .block-wines {grid-template-columns: 305px 305px 305px 305px; height: calc(100vh - 400px) !important;
                    & .wine-preview .content-box .wine-content {width: 90%; padding: 120px;
                        & .wine-detail {grid-template-columns: 55% 1px 1fr; height: calc(70vh - 140px);
                            & .block-product {
                                & .introduction {
                                    & .product-name .name {font-size: 44px; line-height: 44px;}
                                    & .product-description {font-size: 18px;}
                                }
                                & .bottom {
                                    & img {width: 65%;}
                                    & .caracteristics-block .line .caracteristic {font-size: 18px;}
                                }
                            }
                            & .block-area {height: calc(70vh - 180px);
                                & .bottom {height: calc(58vh - 380px);
                                    & .area-description {font-size: 18px;}
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
