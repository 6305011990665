#pages[data-route="about_us"] {background: $nude; padding-bottom: 50px;
    & .banner-block {
        & .banner {background-size: cover; background-position: right 30%; border-radius: 40% 0 40% 60%; box-shadow: 12px 12px 24px #a3a3a3, -12px -12px 24px rgba(255, 244, 220); width: 80%; height: 500px; margin-left: 30%;}
        & img {top: 30px; left: 100px; width: auto; height: 400px;}
    }
    & .content-block {padding-bottom: 30px;
        & h1, h2 {font-family: $charm; color: $black; text-shadow: 2px 3px 3px #00000055; padding-left: 50px; margin: 50px 0;}
        & .first-block {margin-bottom: 30px; padding: 0 120px;
            & .left-block {
                & img {float: left; width: 25%;
                    &:nth-child(1) {border-radius: 15px 0 0 15px;}
                    &:nth-child(2) {border-radius: 0 15px 15px 0; margin: 0 20px 0 10px;}
                }
                & p {line-height: 27px; letter-spacing: 1px; margin-bottom: 10px;}
            }
        }
        & .second-block {
            & .team-picture {background-size: cover; background-position: center; border-radius: 15px; box-shadow: 12px 12px 24px #a3a3a3, -12px -12px 24px rgba(255, 244, 220); width: calc(100% - 240px); height: 440px;}
        }
    }
}

@media (max-width: 767.98px) {
    #pages[data-route="about_us"] {padding-bottom: 0;
        & .banner-block {
            & .banner {height: 200px; margin-left: 24%;}
            & img {top: 10px; left: 10px; height: 150px;}
        }
        & .content-block {
            & h1 {font-size: 13vw; line-height: 15vw; margin: 50px 0 20px; padding-left: 30px;}
            & h2 {font-size: 13vw; line-height: 15vw; text-align: center; padding: 0;
                &:last-child {font-size: 8vw; padding: 0 20px;}
            }
            & .first-block {padding: 0 20px;
                & .left-block {display: flex; flex-wrap: wrap;
                    & img {width: calc(50% - 5px);
                        &:nth-child(2) {margin: 0 0 0 10px;}
                    }
                    & p:first-of-type {margin-top: 30px;}
                }
            }
            & .second-block .team-picture {width: calc(100vw - 30px); height: 300px;}
        }
    }
}

@media only screen and (min-width : 768px) and (max-width : 1280px) and (orientation : portrait) {
    #pages[data-route="about_us"] {
        & .banner-block img {left: 30px;}
        & .content-block {
            & .first-block {padding: 0 50px;}
            & .second-block .team-picture {width: calc(100% - 100px);}
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1280px) and (orientation: landscape) {
}

@media screen and (min-width: 1900px) {
    #pages[data-route="about_us"] {
        & .banner-block {
            & img {top: 70px; left: 210px; height: 650px;}
            & .banner {height: 750px;}
        }
        & .content-block {padding: 100px 15% 30px;
            & .second-block .team-picture {height: 650px;}
        }
    }
}
